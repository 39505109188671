import React, { useState, useEffect } from "react";
import {
  getFirestore,
  collection,
  getDocs,
  addDoc,
  updateDoc,
  doc,
} from "firebase/firestore";
import Locationscroll from "../../../../components/Locationscroll/Locationscroll";
import { useAuth } from "../../../../AuthProvider";

const ManageUsers = ({ refreshUsers }) => {
  const { isAdmin } = useAuth();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [newUser, setNewUser] = useState({
    name: "",
    phoneNumber: "",
    email: "",
  });
  const [selectedUser, setSelectedUser] = useState(null);
  const [updateTrigger, setUpdateTrigger] = useState(false); // State to trigger re-fetch

  const db = getFirestore();

  // Fetch users
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const usersSnapshot = await getDocs(collection(db, "users"));
        const usersData = usersSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setUsers(usersData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching users:", error);
        setLoading(false);
      }
    };

    fetchUsers();
  }, [updateTrigger]); // Add updateTrigger as a dependency to re-run on update

  const handleSaveUser = async () => {
    try {
      if (selectedUser) {
        // Update existing user
        const userDoc = doc(db, "users", selectedUser.id);
        const updatedUserData = {
          ...(newUser.name !== "" ? { name: newUser.name } : {}),
          ...(newUser.email !== "" ? { email: newUser.email } : {}),
          ...(newUser.phoneNumber !== ""
            ? { phoneNumber: newUser.phoneNumber }
            : {}),
        };
        await updateDoc(userDoc, updatedUserData);
        alert("User updated successfully!");

        // Reset form fields and trigger a re-fetch
        setNewUser({
          name: "",
          phoneNumber: "",
          email: "",
        });
        setSelectedUser(null);
        setUpdateTrigger((prev) => !prev); // Trigger re-fetch
        if (refreshUsers) refreshUsers();
      } else {
        // Add new user
        const newUserData = {};
        if (newUser.name !== "") newUserData.name = newUser.name;
        if (newUser.email !== "") newUserData.email = newUser.email;
        if (newUser.phoneNumber !== "")
          newUserData.phoneNumber = newUser.phoneNumber;

        // Use addDoc to let Firestore generate a unique ID
        await addDoc(collection(db, "users"), newUserData);
        alert("User added successfully!");

        // Reset form fields and trigger a re-fetch
        setNewUser({
          name: "",
          phoneNumber: "",
          email: "",
        });
        setUpdateTrigger((prev) => !prev); // Trigger re-fetch
        if (refreshUsers) refreshUsers();
      }
    } catch (error) {
      console.error("Error saving user:", error);
    }
  };

  return (
    <>
      <div>
        <Locationscroll />
        <div className="manage-users-page">
          {loading ? (
            <div>Loading...</div>
          ) : (
            <>
              <div>
                <label>Edit User:</label>
                <select
                  value={selectedUser?.id || ""}
                  onChange={(e) => {
                    const user = users.find((u) => u.id === e.target.value);
                    setSelectedUser(user);
                    setNewUser({
                      name: user?.name || "",
                      phoneNumber: user?.phoneNumber || "",
                      email: user?.email || "",
                    });
                  }}
                >
                  <option value="">Select a user</option>
                  {users.map((user) => (
                    <option key={user.id} value={user.id}>
                      {user.name || user.phoneNumber}
                    </option>
                  ))}
                </select>

                <label>Name:</label>
                <input
                  type="text"
                  value={newUser.name}
                  onChange={(e) =>
                    setNewUser({ ...newUser, name: e.target.value })
                  }
                />
                <label>Phone Number:</label>
                <input
                  type="text"
                  placeholder="Add area code"
                  value={newUser.phoneNumber}
                  onChange={(e) =>
                    setNewUser({ ...newUser, phoneNumber: e.target.value })
                  }
                />
                <label>Email:</label>
                <input
                  type="text"
                  value={newUser.email}
                  onChange={(e) =>
                    setNewUser({ ...newUser, email: e.target.value })
                  }
                />
                <button onClick={handleSaveUser}>
                  {selectedUser ? "Update User" : "Add User"}
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ManageUsers;
