import React, { useState, useEffect } from "react";
import { useAuth } from "../../../AuthProvider";

const Overview = () => {
  const { userData } = useAuth();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate loading by setting loading to false once userData is available
    if (userData) {
      setLoading(false);
    }
  }, [userData]);

  return (
    <div className="row2" id="flex">
      <div className="main">
        <h2>The Watoto Library - Intranet</h2>
        {loading ? (
          <div>Loading...</div>
        ) : (
          <div>
            {" "}
            <p>
              Logged in as: {userData && userData.name} |{" "}
              {userData && userData.description_1}{" "}
              {userData && userData.description_2
                ? `& ${userData.description_2}`
                : ""}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Overview;
