import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Locationscroll from "../../../components/Locationscroll/Locationscroll";
import { useAuth } from "../../../AuthProvider";
import {
  addDoc,
  collection,
  getDocs,
  query,
  where,
  serverTimestamp,
} from "firebase/firestore"; // Firestore functions
import { db } from "../../../firebase"; // Firestore config
import "./Reports.css";

const sortReportsByWeek = (reports) => {
  return reports.sort((a, b) => {
    const weekA = parseInt(a.week.replace("Week ", ""));
    const weekB = parseInt(b.week.replace("Week ", ""));
    return weekB - weekA; // Sort in descending order (most recent week first)
  });
};

// Function to check if today is between Thursday (Day 4) and Sunday (Day 0)
const canCreateReport = () => {
  const today = new Date().getDay(); // 0 = Sunday, 4 = Thursday, 5 = Friday
  return today >= 4 || today === 0; // Allow from Thursday (4) to Sunday (0)
};

// Function to calculate days until next Friday 9 PM
const getDaysUntilFriday9PM = (reportExists) => {
  const today = new Date();
  const currentDay = today.getDay(); // 0 = Sunday, 1 = Monday, ..., 5 = Friday
  const currentTime = today.getHours() * 60 + today.getMinutes(); // Convert time to minutes since 00:00

  const daysUntilFriday = (5 - currentDay + 7) % 7; // 5 is Friday
  const friday9PMTime = 21 * 60; // 21 hours * 60 minutes = 1260 minutes

  if (currentDay === 5 && currentTime > friday9PMTime && reportExists) {
    return 7; // If it's after Friday 9 PM and report exists, next report due next Friday
  }

  // Check if the report was submitted earlier (on Thursday or Friday)
  if ((currentDay === 4 || currentDay === 5) && reportExists) {
    return 7 + daysUntilFriday; // Adjust the due date by adding 7 days to the current difference
  }

  return daysUntilFriday;
};

const Reports = ({ onAdminReportsToReview, onUserReportsToHandle }) => {
  const { isAdmin, userData } = useAuth();
  const navigate = useNavigate(); // Initialize useNavigate

  // State to store existing reports and loading state
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(true); // Add loading state

  const getWeekNumber = (date) => {
    const startDate = new Date(date.getFullYear(), 0, 1); // January 1st
    const startDayOfWeek = startDate.getDay(); // Day of the week for January 1st
    const dayOfYear =
      Math.floor((date - startDate) / (24 * 60 * 60 * 1000)) + 1; // Day of the year

    // Calculate the week number, adjusting for the first partial week
    const weekNumber = Math.ceil((dayOfYear + (startDayOfWeek - 1)) / 7);
    return weekNumber;
  };

  const currentWeek = getWeekNumber(new Date());

  // Calculate days until next Friday 9 PM
  const daysUntilFriday9PM = getDaysUntilFriday9PM();

  useEffect(() => {
    const fetchReports = async () => {
      try {
        if (!userData) return;

        let q;
        if (isAdmin) {
          q = query(collection(db, "reports"));
        } else {
          q = query(
            collection(db, "reports"),
            where("userId", "==", userData.email)
          );
        }

        const querySnapshot = await getDocs(q);
        const fetchedReports = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        const sortedReports = sortReportsByWeek(fetchedReports);
        setReports(sortedReports);
        setLoading(false);

        if (isAdmin) {
          const toReview = sortedReports.filter(
            (report) => report.status === "submitted"
          ).length;
          onAdminReportsToReview(toReview);
        } else {
          const reportExists = sortedReports.some(
            (report) => report.week === `Week ${currentWeek}`
          );

          const toCreate = canCreateReport() && !reportExists ? 1 : 0; // Only set to 1 if report creation is allowed and no report exists for this week
          const toConfirm = sortedReports.filter(
            (report) => report.status === "awaitingRead"
          ).length;

          onUserReportsToHandle({ toCreate, toConfirm });
        }
      } catch (error) {
        console.error("Error fetching reports:", error);
        setLoading(false);
      }
    };

    fetchReports();
  }, [
    userData,
    isAdmin,
    onAdminReportsToReview,
    onUserReportsToHandle,
    currentWeek,
  ]);

  // Create a new report document in Firestore and navigate to the detailed report page
  const handleCreateReport = async (week) => {
    try {
      // Create the new report document in Firestore
      const newReportRef = await addDoc(collection(db, "reports"), {
        userId: userData.email, // Staff user ID
        userName: userData.name, // Staff user name
        week: `Week ${week}`, // The selected week
        content: "", // Initially empty
        status: "draft", // Initialize with draft status
        submittedAt: null,
        feedback: "",
      });

      // Navigate to the report detail page to edit the report content
      navigate(`/reports/${newReportRef.id}`);
    } catch (error) {
      console.error("Error creating report:", error);
    }
  };

  // Navigate to edit the existing report
  const handleEditReport = (reportId) => {
    navigate(`/reports/${reportId}`);
  };

  // Check if a report exists for the specified week
  const reportExistsForWeek = (week) => {
    return reports.find((report) => report.week === `Week ${week}`);
  };

  console.log("Current week:", currentWeek);

  const renderStaffView = () => {
    const reportExists = reportExistsForWeek(currentWeek); // Check if report exists for current week
    const daysUntilFriday9PM = getDaysUntilFriday9PM(reportExists);

    return (
      <>
        <p>
          Reports are due every Friday. Next report is due{" "}
          <b>
            {daysUntilFriday9PM === 0 && !reportExists
              ? "today"
              : `in ${daysUntilFriday9PM} days`}
          </b>
          .
        </p>

        <br />

        <h3>Active Reports</h3>
        {!reportExistsForWeek(currentWeek) && canCreateReport() && (
          <table className="report-table">
            <td>
              <h3>Week {currentWeek}</h3>
            </td>
            <td></td>
            <td>
              <button
                type="button"
                className="create-report-btn"
                onClick={() => handleCreateReport(currentWeek)}
              >
                Create Report
              </button>
            </td>
          </table>
        )}
        {reports &&
          reports
            .filter((report) => report.status !== "completed")
            .map((report) => {
              return (
                <>
                  <div key={report.id} className="report-table">
                    <h3>{report.week}</h3>
                    {report.status === "draft" ? (
                      <>
                        <p>
                          <i className="fa-solid fa-user-pen"></i> Draft created
                        </p>
                        <button
                          type="button"
                          className="orange-btn"
                          onClick={() => handleEditReport(report.id)}
                        >
                          Edit Report
                        </button>
                      </>
                    ) : report.status === "submitted" ? (
                      <>
                        <p>
                          <i className="fa-regular fa-clock"></i> Awaiting
                          feedback
                        </p>
                        <button
                          type="button"
                          className="view-report-btn"
                          onClick={() => handleEditReport(report.id)}
                        >
                          View Report
                        </button>
                      </>
                    ) : report.status === "awaitingRead" ? (
                      <>
                        <p>
                          <i class="fa-regular fa-bell"></i> Feedback available
                        </p>
                        <button
                          type="button"
                          className="view-report-btn"
                          onClick={() => handleEditReport(report.id)}
                        >
                          Read Feedback
                        </button>
                      </>
                    ) : report.status === "completed" ? (
                      <>
                        <p>
                          <i class="fa-solid fa-check"></i> Completed
                        </p>
                        <button
                          type="button"
                          className="view-report-btn"
                          onClick={() => handleEditReport(report.id)}
                        >
                          View
                        </button>
                      </>
                    ) : (
                      "invalid"
                    )}
                  </div>
                </>
              );
            })}
        <br />
        <h3>Completed Reports</h3>
        {reports &&
          reports
            .filter((report) => report.status === "completed")
            .map((report) => {
              return (
                <>
                  <table key={report.id} className="report-table">
                    <td>
                      <h3>{report.week}</h3>
                    </td>
                    <td></td>
                    <td>
                      <button
                        type="button"
                        className="view-report-btn"
                        onClick={() => handleEditReport(report.id)}
                      >
                        View Report
                      </button>
                    </td>
                  </table>
                </>
              );
            })}
      </>
    );
  };

  const renderAdminView = () => {
    return (
      <>
        <br />
        <h3>Active Reports</h3>

        {reports && reports.length > 0 ? (
          reports.filter((report) => report.status !== "completed").length >
          0 ? (
            reports
              .filter((report) => report.status !== "completed")
              .filter((report) => report.status !== "draft")

              .map((report) => (
                <table key={report.id} className="report-table">
                  <td>
                    <h3 onClick={() => handleEditReport(report.id)}>
                      {report.week} - {report.userName}
                    </h3>
                  </td>

                  {report.status === "draft" ? (
                    <>
                      <td>
                        <i className="fa-solid fa-user-pen"></i> Draft created
                      </td>
                      <td></td>
                    </>
                  ) : report.status === "submitted" ? (
                    <>
                      <td>
                        <i class="fa-solid fa-pencil"></i> Needs feedback
                      </td>
                      <td>
                        <button
                          type="button"
                          className="submit-report-btn"
                          onClick={() => handleEditReport(report.id)}
                        >
                          Review Report
                        </button>
                      </td>
                    </>
                  ) : report.status === "awaitingRead" ? (
                    <>
                      <td>
                        <i class="fa-regular fa-clock"></i> Awaiting
                        confirmation
                      </td>
                      <td>
                        <button
                          type="button"
                          className="view-report-btn"
                          onClick={() => handleEditReport(report.id)}
                        >
                          View Report
                        </button>
                      </td>
                    </>
                  ) : (
                    "invalid"
                  )}
                </table>
              ))
          ) : (
            ""
          )
        ) : (
          <p>You have no reports to go through - woho!</p>
        )}
        <br />
        <h3>Completed Reports</h3>
        {reports && reports.length > 0 ? (
          reports.filter((report) => report.status === "completed").length >
          0 ? (
            reports
              .filter((report) => report.status === "completed")

              .map((report) => (
                <table key={report.id} className="report-table">
                  <td>
                    <h3>{report.userName}</h3>
                  </td>

                  <td>
                    <h3>{report.week}</h3>
                  </td>

                  <td>
                    <button
                      type="button"
                      className="view-report-btn"
                      onClick={() => handleEditReport(report.id)}
                    >
                      View Report
                    </button>
                  </td>
                </table>
              ))
          ) : (
            <p>No completed reports</p>
          )
        ) : (
          <p>No reports available</p>
        )}
      </>
    );
  };

  // Determine which view to render
  const renderView = () => {
    return isAdmin ? renderAdminView() : renderStaffView();
  };

  return (
    <>
      <div>
        <Locationscroll />
        <div className="row2" id="flex">
          <div className="main">
            <h2>Reports</h2>
            <div className="report-list">
              {loading ? <div>Loading...</div> : renderView()}
            </div>

            <br />
          </div>
        </div>
      </div>
    </>
  );
};

export default Reports;
