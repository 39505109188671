import React, { useState, useEffect } from "react";
import { getFirestore, collection, getDocs } from "firebase/firestore";
import Locationscroll from "../../../components/Locationscroll/Locationscroll";
import { useAuth } from "../../../AuthProvider";
import "./Students.css"; // Add custom CSS

const Students = () => {
  const { isAdmin, userData } = useAuth();
  const [loading, setLoading] = useState(true);
  const [studentClasses, setStudentClasses] = useState([]);
  const [selectedClass, setSelectedClass] = useState("");
  const [students, setStudents] = useState([]);
  const [sortCriteria, setSortCriteria] = useState("");

  useEffect(() => {
    const fetchClasses = async () => {
      try {
        if (!userData) {
          setLoading(true);
          return;
        }

        const db = getFirestore();
        const classesSnapshot = await getDocs(collection(db, "students"));
        const classesData = classesSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setStudentClasses(classesData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchClasses();

    // Retrieve last selected class and sorting criteria from localStorage
    const savedClass = localStorage.getItem("selectedClass");
    const savedSortCriteria = localStorage.getItem("sortCriteria");

    if (savedClass) {
      setSelectedClass(savedClass);
    }

    if (savedSortCriteria) {
      setSortCriteria(savedSortCriteria);
    }
  }, [userData]);

  // Fetch students whenever selectedClass or studentClasses changes
  useEffect(() => {
    if (selectedClass && studentClasses.length > 0) {
      fetchStudentsForClass(selectedClass);
    }
  }, [selectedClass, studentClasses]);

  const fetchStudentsForClass = async (classId) => {
    const selectedClassData = studentClasses.find((cls) => cls.id === classId);

    if (selectedClassData && selectedClassData.students_list) {
      setStudents(selectedClassData.students_list);
    } else {
      setStudents([]);
    }
  };

  const handleClassChange = (e) => {
    const selectedClassId = e.target.value;
    setSelectedClass(selectedClassId);
    fetchStudentsForClass(selectedClassId);
    localStorage.setItem("selectedClass", selectedClassId); // Save selected class in localStorage
  };

  const handleSortChange = (criteria) => {
    setSortCriteria(criteria);
    localStorage.setItem("sortCriteria", criteria); // Save sorting criteria in localStorage
  };

  const getAttendanceSummary = (attendance) => {
    if (!attendance)
      return {
        presentCount: 0,
        notPresentCount: 0,
        excusedCount: 0,
        lateCount: 0,
        totalLessons: 0,
      };

    let presentCount = 0;
    let notPresentCount = 0;
    let excusedCount = 0;
    let lateCount = 0;

    // Loop through each day in attendance
    Object.values(attendance || {}).forEach((dailyAttendance) => {
      // Loop through each lesson for that day
      Object.values(dailyAttendance).forEach((status) => {
        if (status === "Present") {
          presentCount++;
        } else if (status === "Not Present") {
          notPresentCount++;
        } else if (status === "Excused") {
          excusedCount++;
        } else if (status === "Late") {
          lateCount++;
        }
      });
    });

    const totalLessons =
      presentCount + notPresentCount + excusedCount + lateCount;

    return {
      presentCount,
      notPresentCount,
      excusedCount,
      lateCount,
      totalLessons,
    };
  };

  const getGradesSummary = (grades) => {
    if (!grades) return "N/A";

    const totalExams = Object.keys(grades).length;
    const totalGrades = Object.values(grades).reduce((total, exam) => {
      const examTotal = Object.values(exam).reduce(
        (sum, grade) => sum + grade,
        0
      );
      return total + examTotal;
    }, 0);

    const averageGrade = totalExams > 0 ? totalGrades / totalExams : 0;
    return averageGrade.toFixed(2);
  };

  // Sorting logic based on the selected criteria
  const sortStudents = (students) => {
    return students.slice().sort((a, b) => {
      const aAttendance = getAttendanceSummary(a.attendance);
      const bAttendance = getAttendanceSummary(b.attendance);

      switch (sortCriteria) {
        case "present":
          return bAttendance.presentCount - aAttendance.presentCount;
        case "notPresent":
          return bAttendance.notPresentCount - aAttendance.notPresentCount;
        case "excused":
          return bAttendance.excusedCount - aAttendance.excusedCount;
        case "late":
          return bAttendance.lateCount - aAttendance.lateCount;
        default:
          return 0;
      }
    });
  };

  return (
    <>
      <Locationscroll />
      <div className="students-page">
        <h2>Manage Students</h2>

        {loading ? (
          <div>Loading...</div>
        ) : (
          <>
            <div>
              <label>Select Class: </label>
              <select value={selectedClass} onChange={handleClassChange}>
                <option value="">Select a Class</option>
                {studentClasses.map((cls) => (
                  <option key={cls.id} value={cls.id}>
                    {cls.name}
                  </option>
                ))}
              </select>
            </div>

            {selectedClass && (
              <>
                <br />
                <br />
                <div className="sort-buttons">
                  <button onClick={() => handleSortChange("present")}>
                    Sort by Present
                  </button>
                  <button onClick={() => handleSortChange("notPresent")}>
                    Sort by Not Present
                  </button>
                  <button onClick={() => handleSortChange("excused")}>
                    Sort by Excused
                  </button>
                  <button onClick={() => handleSortChange("late")}>
                    Sort by Late
                  </button>
                </div>
                <table className="students-table">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Present</th>
                      <th>Not Present</th>
                      <th>Excused</th>
                      <th>Late</th>
                      <th>Total Lessons</th>
                      <th>Average Grade</th>
                      {/* {isAdmin && <th>Actions</th>} */}
                    </tr>
                  </thead>
                  <tbody>
                    {sortStudents(students).map((student) => {
                      const attendanceSummary = getAttendanceSummary(
                        student.attendance
                      );
                      const averageGrade = getGradesSummary(student.grades);

                      return (
                        <tr key={student.id}>
                          <td>{student.name}</td>
                          <td>{attendanceSummary.presentCount}</td>
                          <td>{attendanceSummary.notPresentCount}</td>
                          <td>{attendanceSummary.excusedCount}</td>
                          <td>{attendanceSummary.lateCount}</td>
                          <td>{attendanceSummary.totalLessons}</td>
                          <td>{averageGrade}</td>
                          {/* {isAdmin && (
                            <td>
                              <button>Edit</button>
                              <button className="remove">Remove</button>
                            </td>
                          )} */}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default Students;
