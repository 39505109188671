import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Navbar,
  Footer,
  Side,
  Slideshow,
  Section,
  MyLink,
} from "../../components";
import { doc, updateDoc, getDoc, deleteDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { useParams } from "react-router-dom";
import NextItems from "../../components/NextItems/NextItems";
import { useWhatWeDo } from "../WhatWeDo/WhatWeDoContext";

const Wwd = ({ isAdmin }) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const { whatWeDo, setWhatWeDo, whatWeDoLoading } = useWhatWeDo(); // Destructure whatWeDoLoading from the context
  const { wwdUrl } = useParams();
  const navigate = useNavigate();

  const wwd = whatWeDo.find((wwd) => wwd.url === wwdUrl);

  const toggleWwdStatus = async () => {
    if (!wwdUrl) return; // Guard clause

    const wwdRef = doc(db, "whatWeDo", wwdUrl); // Construct Firestore document reference based on the URL

    try {
      setIsProcessing(true);
      const docSnapshot = await getDoc(wwdRef); // Retrieve document snapshot

      if (docSnapshot.exists()) {
        const currentStatus = docSnapshot.data().status;
        const newStatus = currentStatus === "Published" ? "Draft" : "Published";

        await updateDoc(wwdRef, { status: newStatus });

        console.log(`Wwd status updated to ${newStatus}`);
        setTimeout(() => {
          // Update the status in the context state
          const updatedWhatWeDo = whatWeDo.map((item) =>
            item.url === wwdUrl ? { ...item, status: newStatus } : item
          );
          setWhatWeDo(updatedWhatWeDo);
          setIsProcessing(false);
        }, 2000);
      } else {
        alert("Wwd document does not exist");
      }
    } catch (error) {
      alert("Error updating Wwd status: ", error);
    }
  };

  const handleUpdateSection = async (updatedSectionFields) => {
    const { sectionId, sectionTitle, sectionDescription } =
      updatedSectionFields;

    if (!wwdUrl || !sectionId) {
      console.error("Missing Wwd URL or Section ID.");
      return;
    }

    const wwdRef = doc(db, "whatWeDo", wwdUrl);
    try {
      const docSnapshot = await getDoc(wwdRef);
      if (docSnapshot.exists()) {
        const sections = docSnapshot.data().sections || [];

        // Update the specific section by its ID
        const updatedSections = sections.map((section) => {
          if (section.section_id === sectionId) {
            return {
              ...section,
              section_title: sectionTitle || section.section_title,
              section_description:
                sectionDescription || section.section_description,
            };
          }
          return section;
        });

        // Update Firestore document with updated sections
        await updateDoc(wwdRef, { sections: updatedSections });

        console.log("Section updated successfully.");
      } else {
        console.log("Document not found.");
      }
    } catch (error) {
      console.error("Error updating section: ", error);
    }
  };

  // Inside Wwd component

  const addNewSection = async () => {
    if (!wwd) return;

    const sections = wwd.sections || [];

    // Generate a new section ID
    const newSectionId =
      sections.length > 0
        ? sections.reduce(
            (maxId, section) => Math.max(maxId, section.section_id),
            0
          ) + 1
        : 1;

    const newSection = {
      section_id: newSectionId,
      section_title: "New Section Title",
      section_description: "New Section Description",
      section_image: "",
    };

    const updatedSections = [...sections, newSection];

    // Update Firestore
    const wwdRef = doc(db, "whatWeDo", wwdUrl);
    try {
      await updateDoc(wwdRef, {
        sections: updatedSections,
      });
      console.log("Wwd updated with new section in Firestore");

      // Update local state
      setWhatWeDo((prevWhatWeDo) =>
        prevWhatWeDo.map((item) =>
          item.url === wwdUrl ? { ...item, sections: updatedSections } : item
        )
      );
    } catch (error) {
      console.error("Error adding new section to Firestore: ", error);
    }
  };

  const deleteSection = async (sectionId) => {
    if (!wwd || !wwd.sections) return;

    const updatedSections = wwd.sections
      .filter((section) => section.section_id !== sectionId)
      .map((section, index) => ({ ...section, section_id: index + 1 })); // Reassign section_ids

    // Update Firestore
    const wwdRef = doc(db, "whatWeDo", wwdUrl);
    try {
      await updateDoc(wwdRef, {
        sections: updatedSections,
      });
      console.log("Wwd updated after section deletion in Firestore");

      // Update local state
      setWhatWeDo((prevWhatWeDo) =>
        prevWhatWeDo.map((item) =>
          item.url === wwdUrl ? { ...item, sections: updatedSections } : item
        )
      );
    } catch (error) {
      console.error("Error deleting section in Firestore: ", error);
    }
  };

  const handleUpdateWwd = async (updatedFields) => {
    const { header, description } = updatedFields;

    if (!wwdUrl) {
      console.error("Missing Wwd URL.");
      return;
    }

    const wwdRef = doc(db, "whatWeDo", wwdUrl);
    try {
      await updateDoc(wwdRef, {
        title: header || wwd.title,
        description: description || wwd.description,
      });

      console.log("Wwd main section updated successfully.");

      // Update local state if necessary
      setWhatWeDo((prevWhatWeDo) =>
        prevWhatWeDo.map((item) =>
          item.url === wwdUrl
            ? { ...item, title: header, description: description }
            : item
        )
      );
    } catch (error) {
      console.error("Error updating Wwd main section: ", error);
    }
  };

  const deleteWwdDocument = async () => {
    try {
      const confirmDeletion = window.confirm(
        "Are you sure you want to delete this program? This action cannot be reversed."
      );
      if (!confirmDeletion) return;
      setIsProcessing(true);
      await deleteDoc(doc(db, "whatWeDo", wwdUrl));
      // Remove the deleted document from the state
      const updatedWhatWeDo = whatWeDo.filter((item) => item.url !== wwdUrl);
      setWhatWeDo(updatedWhatWeDo);
      setTimeout(() => {
        navigate("/admin/programs");
        setIsProcessing(false);
      }, 2000);
    } catch (error) {
      console.error("Error deleting Wwd document:", error);
    }
  };

  return (
    <div>
      <Navbar isAdmin={isAdmin} />
      <div className="row" id="flex">
        <Side isProcessing={isProcessing} />
        <div className="main">
          {whatWeDoLoading || !wwd ? (
            <div className="loading-container">
              <div className="loading-animation"></div>
              <div className="loading-text">Loading...</div>
            </div>
          ) : !wwd ? (
            <div>
              <p>No article found</p> <br />
              <MyLink to="/">
                <u>Go Home</u>
              </MyLink>
            </div>
          ) : (
            <div>
              <MyLink to={`/programs#${wwdUrl}`}>
                <div className="div-back">
                  <p className="allNewsLetters">
                    <i className="fa-solid fa-arrow-left"></i>Back to Programs
                  </p>
                </div>
              </MyLink>
              {isAdmin && (
                <>
                  {wwd && (
                    <>
                      <span className={`status-${wwd.status?.toLowerCase()}`}>
                        {wwd.status === "Published" ? (
                          <>
                            {wwd.status} <i className="fa fa-check-circle"></i>
                          </>
                        ) : (
                          <>
                            {wwd.status} <i className="fa fa-pencil-alt"></i>
                          </>
                        )}
                      </span>
                      <span
                        className="div-readmore status"
                        onClick={toggleWwdStatus}
                      >
                        {" "}
                        {wwd.status === "Published" ? (
                          <>
                            <i className="fa-solid fa-download"></i> Unpublish
                          </>
                        ) : (
                          <>
                            <i className="fa-solid fa-upload"></i> Publish
                          </>
                        )}
                      </span>
                      <span
                        className="div-readmore status"
                        onClick={deleteWwdDocument}
                      >
                        <i className="fa-solid fa-trash-can"></i> Delete
                      </span>
                    </>
                  )}
                </>
              )}
              {wwd && (
                <div className="article" id={wwdUrl}>
                  <Section
                    wwdId={wwd.url} // Pass the correct wwdId for "whatWeDo"
                    header={wwd.title}
                    coming={wwd.coming}
                    instructor={wwd.instructor}
                    places={wwd.places}
                    openingHours={wwd.openingHours}
                    description={wwd.description}
                    img={wwd.img}
                    isAdmin={isAdmin}
                    isArticle={false} // This should be false for "whatWeDo"
                    onUpdateArticle={handleUpdateWwd}
                  />
                  {wwd?.sections?.map((section) => (
                    <div key={section.section_id}>
                      <Section
                        wwdId={wwd.url} // Correct wwdId for "whatWeDo"
                        sectionId={section.section_id}
                        sectionTitle={section.section_title}
                        img={section.section_image}
                        sectionDescription={section.section_description}
                        isAdmin={isAdmin}
                        isArticle={false} // This should be false for "whatWeDo"
                        onUpdateSection={handleUpdateSection} // Correct handler
                        onDeleteSection={() =>
                          deleteSection(section.section_id)
                        }
                      />
                    </div>
                  ))}
                </div>
              )}
              <br />
              {isAdmin && (
                <div className="article">
                  <span
                    onClick={addNewSection}
                    className="div-readmore addSection"
                  >
                    Add New Section <i className="fa-solid fa-plus"></i>
                  </span>
                </div>
              )}
              {/* <br />
              <div className="article">
                <NextItems
                  items={whatWeDo}
                  currentIdentifier={wwdUrl}
                  identifierType="url"
                />
              </div> */}
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Wwd;
