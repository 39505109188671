import React, { useState, useEffect } from "react";
import {
  getFirestore,
  collection,
  getDocs,
  addDoc,
  doc,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import Locationscroll from "../../../../components/Locationscroll/Locationscroll";
import ManageUsers from "./ManageUsers";
import { useAuth } from "../../../../AuthProvider";
import IncomeExpenses from "./IncomeExpenses";
import "./Sponsorships.css";

const Sponsorships = () => {
  const { isAdmin } = useAuth();
  const [grades, setGrades] = useState([]);
  const [students, setStudents] = useState([]);
  const [users, setUsers] = useState([]);
  const [updateTrigger, setUpdateTrigger] = useState(false);
  const [editedRows, setEditedRows] = useState({});
  const [lastUpdatedSponsor, setLastUpdatedSponsor] = useState(null);
  const [sponsors, setSponsors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [newSponsor, setNewSponsor] = useState({
    name: "",
    phone: "",
    email: "",
  });
  const [edits, setEdits] = useState({});
  const [selectedGrade, setSelectedGrade] = useState(null);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [selectedSponsor, setSelectedSponsor] = useState(null);
  const [commitmentDetails, setCommitmentDetails] = useState({
    amount: "",
    period: "",
    currency: "NOK",
    startDate: "",
  });
  const [step, setStep] = useState(1);
  const [editMode, setEditMode] = useState(false);
  const [showStudentOptions, setShowStudentOptions] = useState(false);
  const [sortOption, setSortOption] = useState(() => {
    return localStorage.getItem("sponsorshipsSortOption") || "";
  });
  const [sentNewsletters, setSentNewsletters] = useState([]);
  const [lastNewsletterDates, setLastNewsletterDates] = useState({});

  const db = getFirestore();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const gradesSnapshot = await getDocs(collection(db, "students"));
        const gradesData = gradesSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        const usersSnapshot = await getDocs(collection(db, "users"));
        const usersData = usersSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        const sponsorshipsSnapshot = await getDocs(
          collection(db, "sponsorships")
        );
        const sponsorshipsData = sponsorshipsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setGrades(gradesData);
        setUsers(usersData);
        setSponsors(sponsorshipsData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);
  // Fetch students when grade is selected

  useEffect(() => {
    const fetchStudentsForGrade = async (gradeId) => {
      const selectedGradeDoc = grades.find((grade) => grade.id === gradeId);

      if (selectedGradeDoc && selectedGradeDoc.students_list) {
        setStudents(selectedGradeDoc.students_list);
      } else {
        setStudents([]);
      }
    };

    if (selectedGrade) {
      fetchStudentsForGrade(selectedGrade);
    }
  }, [selectedGrade, grades]);

  useEffect(() => {
    if (lastUpdatedSponsor) {
      alert(`Sponsorship updated successfully!`);
    }
  }, [lastUpdatedSponsor]);

  useEffect(() => {
    localStorage.setItem("sponsorshipsSortOption", sortOption);
  }, [sortOption]);

  useEffect(() => {
    const fetchSentNewsletters = async () => {
      try {
        const newslettersSnapshot = await getDocs(
          collection(db, "newsletters")
        );
        const newslettersData = newslettersSnapshot.docs
          .filter((doc) => doc.data().type === "sent")
          .map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

        setSentNewsletters(newslettersData);

        // Create a mapping from sponsorshipId to last sent date
        const sponsorLastSentDate = {};
        newslettersData.forEach((newsletter) => {
          const sponsorshipId = newsletter.sponsorshipId;
          const sentDate = newsletter.sentDate;
          if (sponsorshipId) {
            if (!sponsorLastSentDate[sponsorshipId]) {
              sponsorLastSentDate[sponsorshipId] = sentDate;
            } else {
              // Keep the latest date
              if (
                new Date(sentDate) >
                new Date(sponsorLastSentDate[sponsorshipId])
              ) {
                sponsorLastSentDate[sponsorshipId] = sentDate;
              }
            }
          }
        });

        setLastNewsletterDates(sponsorLastSentDate);
      } catch (error) {
        console.error("Error fetching sent newsletters:", error);
      }
    };

    fetchSentNewsletters();
  }, []);

  const refreshUsers = async () => {
    try {
      const usersSnapshot = await getDocs(collection(db, "users"));
      const usersData = usersSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setUsers(usersData);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const assignSponsor = async (wantsStudent) => {
    try {
      const studentName = wantsStudent
        ? students.find((student) => student.id === selectedStudent)?.name
        : null;

      let paymentDay = "1"; // Default to "1"
      if (commitmentDetails.startDate) {
        const date = new Date(commitmentDetails.startDate);
        if (!isNaN(date)) {
          paymentDay = date.getDate().toString();
        }
      }

      // Create a new sponsorship document
      const docRef = await addDoc(collection(db, "sponsorships"), {
        studentName: wantsStudent ? studentName : null,
        sponsorId: selectedSponsor,
        commitmentAmount: commitmentDetails.amount,
        commitmentPeriod: commitmentDetails.period,
        currency: commitmentDetails.currency,
        startDate: commitmentDetails.startDate || null,
        paymentDay: paymentDay,
        status: commitmentDetails.status || "Pending",
        includeInMonthlyIncome: true, // Default to included
      });

      // Update state with the new sponsor
      setSponsors((prevSponsors) => [
        ...prevSponsors,
        {
          id: docRef.id,
          studentName: wantsStudent ? studentName : null,
          sponsorId: selectedSponsor,
          commitmentAmount: commitmentDetails.amount,
          commitmentPeriod: commitmentDetails.period,
          currency: commitmentDetails.currency,
          startDate: commitmentDetails.startDate || null,
          paymentDay: paymentDay,
          status: commitmentDetails.status || "Pending",
          includeInMonthlyIncome: true, // Default to included
        },
      ]);

      alert("Sponsorship created successfully!");

      // Reset all input fields
      setSelectedSponsor(null);
      setCommitmentDetails({
        amount: "",
        period: "",
        currency: "NOK",
        startDate: "",
        status: "",
      });
      setSelectedGrade(null);
      setSelectedStudent(null);
      setShowStudentOptions(false);
      setStep(1);
    } catch (error) {
      console.error("Error assigning sponsor:", error);
      alert("Error assigning sponsor");
      setStep(1);
    }
  };

  const markRowAsEdited = (sponsorId) => {
    setEditedRows((prevEditedRows) => ({
      ...prevEditedRows,
      [sponsorId]: true,
    }));
  };

  const handleSave = async (sponsor) => {
    const updatedData = {
      ...sponsor,
      ...edits[sponsor.id],
    };

    try {
      // Update the sponsorship document in Firestore
      const sponsorDoc = doc(db, "sponsorships", sponsor.id);
      await updateDoc(sponsorDoc, updatedData);

      // Update the sponsors state
      setSponsors((prevSponsors) =>
        prevSponsors.map((sp) => (sp.id === sponsor.id ? updatedData : sp))
      );

      // Clear the edits and editedRows for this row
      setEdits((prevEdits) => {
        const updatedEdits = { ...prevEdits };
        delete updatedEdits[sponsor.id];
        return updatedEdits;
      });

      setEditedRows((prevEditedRows) => {
        const updatedRows = { ...prevEditedRows };
        delete updatedRows[sponsor.id];
        return updatedRows;
      });

      setUpdateTrigger((prev) => !prev); // Trigger re-fetch if needed
      setLastUpdatedSponsor(updatedData); // Set the last updated sponsor
    } catch (error) {
      console.error("Error updating sponsorship:", error);
    }
  };

  const getStatusStyle = (status) => {
    switch (status) {
      case "Active":
        return {
          color: "#5cb85c",
          fontWeight: "bold",
        };
      case "Pending":
        return {
          color: "#f0ad4e",
          fontWeight: "bold",
        };
    }
  };

  return (
    <>
      <Locationscroll />
      <div className="row-2">
        <div className="main">
          <div className="sponsorship-page">
            <h2>Sponsorships</h2>

            {loading ? (
              <div>Loading...</div>
            ) : (
              <>
                <br />
                <h3>Add / Update users</h3>
                <div className="manage-users-parent">
                  <ManageUsers refreshUsers={refreshUsers} />
                </div>
                <br />
                <h3>Assign a Sponsorship</h3>
                <div className="assign-sponsorship-parent">
                  {step === 1 && (
                    <>
                      <div>
                        <select
                          value={selectedSponsor}
                          onChange={(e) => setSelectedSponsor(e.target.value)}
                        >
                          <option value="">Select a user</option>
                          {users.map((user) => (
                            <option key={user.id} value={user.id}>
                              {user.name || user.phoneNumber}
                            </option>
                          ))}
                        </select>

                        <div>
                          <span> has committed to pay </span>
                          <select
                            value={commitmentDetails.currency}
                            onChange={(e) =>
                              setCommitmentDetails({
                                ...commitmentDetails,
                                currency: e.target.value,
                              })
                            }
                          >
                            <option value="NOK">NOK</option>
                            <option value="KES">KES</option>
                            <option value="USD">USD</option>
                          </select>
                          <input
                            type="number"
                            value={commitmentDetails.amount}
                            onChange={(e) =>
                              setCommitmentDetails({
                                ...commitmentDetails,
                                amount: e.target.value,
                              })
                            }
                          />
                          <span> every </span>
                          <input
                            type="number"
                            value={commitmentDetails.period}
                            onChange={(e) =>
                              setCommitmentDetails({
                                ...commitmentDetails,
                                period: e.target.value,
                              })
                            }
                          />
                          <span> months. </span>
                        </div>
                        <button
                          onClick={() => {
                            if (
                              selectedSponsor &&
                              commitmentDetails.amount &&
                              commitmentDetails.period
                            ) {
                              setStep(2); // Move to step 2 if all required fields are filled
                            } else {
                              alert("Please fill in all required fields.");
                            }
                          }}
                        >
                          Confirm
                        </button>
                      </div>
                    </>
                  )}

                  {step === 2 && (
                    <>
                      <div>
                        <label>Has the sponsor made a payment?</label>
                        <button
                          onClick={() => {
                            setCommitmentDetails({
                              ...commitmentDetails,
                              status: "Active",
                            });
                            setStep(3);
                          }}
                        >
                          Made Payment
                        </button>
                        <button
                          onClick={() => {
                            setCommitmentDetails({
                              ...commitmentDetails,
                              status: "Pending",
                            });
                            setStep(4);
                          }}
                        >
                          Has Not Made Payment
                        </button>
                      </div>
                    </>
                  )}
                  {step === 3 && (
                    <>
                      <div>
                        <span>The first payment was made on: </span>
                        <input
                          type="date"
                          value={commitmentDetails.startDate}
                          onChange={(e) => {
                            setCommitmentDetails({
                              ...commitmentDetails,
                              startDate: e.target.value,
                            });
                          }}
                        />
                        <button
                          onClick={() => {
                            if (commitmentDetails.startDate) {
                              setStep(4);
                            } else {
                              alert("Please select a start date.");
                            }
                          }}
                        >
                          Confirm Date
                        </button>
                      </div>
                    </>
                  )}

                  {step === 4 && (
                    <>
                      <div>
                        <label>
                          Does the sponsor want to sponsor a student?
                        </label>
                        <button
                          onClick={() => {
                            assignSponsor(false);
                          }}
                        >
                          No Student
                        </button>
                        <button
                          onClick={() => {
                            setShowStudentOptions(true);
                            setStep(5);
                          }}
                        >
                          Wants to Sponsor a Student
                        </button>
                      </div>
                    </>
                  )}

                  {step === 5 && (
                    <>
                      {showStudentOptions && (
                        <div>
                          <label>Select Grade:</label>
                          <select
                            value={selectedGrade || ""}
                            onChange={(e) => setSelectedGrade(e.target.value)}
                          >
                            <option value="">Select a grade</option>
                            {grades.map((grade) => (
                              <option key={grade.id} value={grade.id}>
                                {grade.name}
                              </option>
                            ))}
                          </select>

                          {selectedGrade && (
                            <>
                              <label>Select Student:</label>
                              <select
                                value={selectedStudent || ""}
                                onChange={(e) =>
                                  setSelectedStudent(e.target.value)
                                }
                              >
                                <option value="">Select a student</option>
                                {students.map((student) => (
                                  <option key={student.id} value={student.id}>
                                    {student.name}{" "}
                                    {student.sponsor === "yes"
                                      ? "(has a sponsor)"
                                      : ""}
                                  </option>
                                ))}
                              </select>
                            </>
                          )}
                          <button onClick={() => assignSponsor(true)}>
                            Confirm Sponsorship
                          </button>
                        </div>
                      )}
                    </>
                  )}
                </div>

                <br />
                <h3>Current Sponsorships</h3>
                <IncomeExpenses />
                <br />

                <div>
                  <button onClick={() => setEditMode(!editMode)}>
                    {editMode ? "Disable Edit Mode" : "Edit Sponsorships"}
                  </button>{" "}
                  <button onClick={() => setSortOption("amount")}>
                    Sort by Amount
                  </button>
                  <button onClick={() => setSortOption("paymentDay")}>
                    Sort by Payment Day
                  </button>
                  <button onClick={() => setSortOption("startDate")}>
                    Sort by Start Date
                  </button>
                </div>

                <table className="current-sponsorships-table">
                  <thead>
                    <tr>
                      <th>Sponsor</th>
                      <th>Student</th>
                      <th>Amount</th>
                      <th>Monthly Interval</th>
                      <th>Payments On Day</th>
                      <th>Start Date</th>
                      <th>Monthly calculation</th>
                      <th>Status</th>
                      <th>Last Newsletter</th>
                      {editMode && <th>Save</th>}
                    </tr>
                  </thead>
                  <tbody>
                    {sponsors
                      .filter(
                        (sponsor) =>
                          sponsor.status !== "Canceled" ||
                          editedRows[sponsor.id]
                      )
                      .sort((a, b) => {
                        // First, move Pending sponsors to the top
                        if (a.status === "Pending" && b.status !== "Pending")
                          return -1;
                        if (a.status !== "Pending" && b.status === "Pending")
                          return 1;

                        // Then sort according to sortOption
                        if (sortOption === "amount") {
                          return (
                            (parseFloat(a.commitmentAmount) || 0) -
                            (parseFloat(b.commitmentAmount) || 0)
                          );
                        } else if (sortOption === "paymentDay") {
                          return (
                            (parseInt(a.paymentDay) || 0) -
                            (parseInt(b.paymentDay) || 0)
                          );
                        } else if (sortOption === "startDate") {
                          const dateA = new Date(a.startDate);
                          const dateB = new Date(b.startDate);
                          return dateA - dateB;
                        } else {
                          return 0; // No additional sorting
                        }
                      })

                      .map((sponsor, index) => {
                        const studentName =
                          students.find(
                            (student) => student.id === sponsor.studentId
                          )?.name ||
                          sponsor.studentName ||
                          "";

                        const lastSentDate =
                          lastNewsletterDates[sponsor.id] || "Never";

                        return (
                          <tr
                            key={sponsor.id}
                            className={
                              editedRows[sponsor.id] ? "edited-row" : ""
                            }
                          >
                            <td>
                              {editMode ? (
                                <select
                                  value={sponsor.sponsorId || ""}
                                  onChange={(e) => {
                                    setSponsors((prevSponsors) =>
                                      prevSponsors.map((sp, idx) =>
                                        idx === index
                                          ? {
                                              ...sp,
                                              sponsorId: e.target.value,
                                            }
                                          : sp
                                      )
                                    );

                                    markRowAsEdited(sponsor.id);
                                  }}
                                >
                                  {users.map((user) => (
                                    <option key={user.id} value={user.id}>
                                      {user.name || user.phoneNumber}
                                    </option>
                                  ))}
                                </select>
                              ) : (
                                users.find(
                                  (user) => user.id === sponsor.sponsorId
                                )?.name || "Unknown"
                              )}
                            </td>

                            <td>{studentName}</td>

                            <td>
                              {editMode ? (
                                <>
                                  <div className="currency-amount-container">
                                    <select
                                      value={sponsor.currency || "NOK"} // Use sponsor's currency
                                      onChange={(e) =>
                                        setSponsors((prevSponsors) =>
                                          prevSponsors.map((sp, idx) =>
                                            idx === index
                                              ? {
                                                  ...sp,
                                                  currency: e.target.value, // Update the sponsor's currency directly
                                                }
                                              : sp
                                          )
                                        )
                                      }
                                    >
                                      <option value="NOK">NOK</option>
                                      <option value="KES">KES</option>
                                      <option value="USD">USD</option>
                                    </select>
                                    <input
                                      type="number"
                                      value={sponsor.commitmentAmount || ""}
                                      onChange={(e) => {
                                        setSponsors((prevSponsors) =>
                                          prevSponsors.map((sp, idx) =>
                                            idx === index
                                              ? {
                                                  ...sp,
                                                  commitmentAmount:
                                                    e.target.value,
                                                }
                                              : sp
                                          )
                                        );
                                        markRowAsEdited(sponsor.id);
                                      }}
                                    />
                                  </div>
                                </>
                              ) : (
                                `${sponsor.currency} ${
                                  sponsor.commitmentAmount || "N/A"
                                }`
                              )}
                            </td>

                            <td>
                              {editMode ? (
                                <input
                                  type="number"
                                  value={sponsor.commitmentPeriod || ""}
                                  onChange={(e) => {
                                    setSponsors((prevSponsors) =>
                                      prevSponsors.map((sp, idx) =>
                                        idx === index
                                          ? {
                                              ...sp,
                                              commitmentPeriod: e.target.value,
                                            }
                                          : sp
                                      )
                                    );
                                    markRowAsEdited(sponsor.id);
                                  }}
                                />
                              ) : (
                                sponsor.commitmentPeriod || "N/A"
                              )}
                            </td>

                            <td>
                              {editMode ? (
                                <input
                                  type="number"
                                  value={sponsor.paymentDay || ""}
                                  onChange={(e) => {
                                    setSponsors((prevSponsors) =>
                                      prevSponsors.map((sp, idx) =>
                                        idx === index
                                          ? {
                                              ...sp,
                                              paymentDay: e.target.value,
                                            }
                                          : sp
                                      )
                                    );
                                    markRowAsEdited(sponsor.id);
                                  }}
                                  min="1"
                                  max="31"
                                />
                              ) : (
                                sponsor.paymentDay || "1"
                              )}
                            </td>

                            <td>
                              {editMode ? (
                                <input
                                  type="date"
                                  value={sponsor.startDate || ""}
                                  onChange={(e) => {
                                    setSponsors((prevSponsors) =>
                                      prevSponsors.map((sp, idx) =>
                                        idx === index
                                          ? {
                                              ...sp,
                                              startDate: e.target.value,
                                            }
                                          : sp
                                      )
                                    );
                                    markRowAsEdited(sponsor.id);
                                  }}
                                />
                              ) : (
                                sponsor.startDate || "N/A"
                              )}
                            </td>
                            <td>
                              {editMode ? (
                                <>
                                  <input
                                    type="checkbox"
                                    checked={
                                      sponsor.includeInMonthlyIncome || false
                                    }
                                    onChange={(e) => {
                                      setSponsors((prevSponsors) =>
                                        prevSponsors.map((sp, idx) =>
                                          idx === index
                                            ? {
                                                ...sp,
                                                includeInMonthlyIncome:
                                                  e.target.checked,
                                              }
                                            : sp
                                        )
                                      );
                                      markRowAsEdited(sponsor.id);
                                    }}
                                  />
                                </>
                              ) : sponsor.includeInMonthlyIncome ? (
                                <i class="fa-solid fa-check"></i>
                              ) : (
                                "Not included"
                              )}
                            </td>

                            <td>
                              {editMode ? (
                                <select
                                  value={
                                    edits[sponsor.id]?.status ||
                                    sponsor.status ||
                                    ""
                                  }
                                  onChange={(e) => {
                                    setEdits((prevEdits) => ({
                                      ...prevEdits,
                                      [sponsor.id]: {
                                        ...prevEdits[sponsor.id],
                                        status: e.target.value,
                                      },
                                    }));
                                    markRowAsEdited(sponsor.id);
                                  }}
                                >
                                  <option value="Pending">Pending</option>
                                  <option value="Active">Active</option>
                                  <option value="Canceled">Canceled</option>
                                </select>
                              ) : (
                                <span style={getStatusStyle(sponsor.status)}>
                                  {sponsor.status}
                                </span>
                              )}
                            </td>
                            <td>{lastSentDate}</td>
                            {editMode && (
                              <td>
                                <button onClick={() => handleSave(sponsor)}>
                                  Save
                                </button>
                              </td>
                            )}
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Sponsorships;
