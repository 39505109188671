import { Home, Article, Articles, Contact, Donate, About, Kibera, Founders, Store, 
    TermsOfSale, Innsamling, Login, Sponsorship, WhatWeDo, Wwd, Intranet, Reports, Report } from './container';

export const sharedRoutes = [
    { path: '/', component: Home},
    { path: '/article/:articleId', component: Article },
    { path: '/articles', component: Articles },
    { path: '/programs', component: WhatWeDo },
    { path: '/programs/:wwdUrl', component: Wwd },
    { path: '/contact', component: Contact },
    { path: '/about', component: About },
    { path: '/about/kibera', component: Kibera },
    { path: '/about/founders', component: Founders },
    { path: '/donate', component: Donate },
    { path: '/store', component: Store },
    { path: '/donate/termsofsale', component: TermsOfSale },
    { path: '/innsamling', component: Innsamling },
    { path: '/login', component: Login },
    { path: '/sponsorship', component: Sponsorship },
    { path: '/sponsorship/:student', component: Sponsorship },
    { path: '/reports', component: Reports},
    { path: '/reports/:id', component: Report},
    { path: '/intranet', component: Intranet },
];
