import React, { useState } from "react";
import { useMsal } from "@azure/msal-react";
import axios from "axios";

const Email = () => {
  const { instance } = useMsal();

  // State variables for form inputs
  const [subject, setSubject] = useState("");
  const [recipient, setRecipient] = useState("");
  const [cc, setCc] = useState(""); // New state for CC email
  const [body, setBody] = useState("");

  const sendEmail = async () => {
    try {
      const account = instance.getActiveAccount();

      if (!account) {
        // Log information before redirect
        localStorage.setItem(
          "email_debug",
          "No account found, triggering redirect"
        );

        // Login using redirect if not authenticated
        await instance.loginRedirect({ scopes: ["Mail.Send"] });
        return; // Redirect will occur, so return early
      }

      // Log account information after redirect
      localStorage.setItem(
        "email_debug",
        `Account found: ${JSON.stringify(account)}`
      );

      // Acquire token silently
      const tokenResponse = await instance.acquireTokenSilent({
        account: instance.getActiveAccount(),
        scopes: ["Mail.Send"],
      });

      // Log the token response
      localStorage.setItem(
        "email_debug",
        `Token acquired: ${JSON.stringify(tokenResponse)}`
      );

      // Prepare the CC recipients array
      const ccRecipients = cc
        ? cc.split(",").map((email) => ({
            emailAddress: { address: email.trim() },
          }))
        : [];

      // Send email using Microsoft Graph API
      const emailData = {
        message: {
          subject: subject, // Use the custom subject from input
          body: {
            contentType: "Text",
            content: body, // Use the custom body from input
          },
          toRecipients: [
            {
              emailAddress: {
                address: recipient, // Use the custom recipient from input
              },
            },
          ],
          ccRecipients: ccRecipients, // Include CC recipients here
        },
        saveToSentItems: "true",
      };

      await axios.post(
        "https://graph.microsoft.com/v1.0/me/sendMail",
        emailData,
        {
          headers: {
            Authorization: `Bearer ${tokenResponse.accessToken}`,
          },
        }
      );

      alert("Email sent successfully!");
    } catch (error) {
      localStorage.setItem(
        "email_debug",
        `Error encountered: ${JSON.stringify(error)}`
      );

      if (
        error.name === "BrowserAuthError" &&
        error.errorCode === "user_cancelled"
      ) {
        console.error("The user canceled the login process.");
        alert("Login was canceled. Please try again.");
      } else if (axios.isAxiosError(error)) {
        // Detailed error logging for API errors
        console.error("API error:", error.response?.data);
        alert("Failed to send the email. Check the console for details.");
      } else {
        console.error("Error during authentication or email sending", error);
        alert("Something went wrong. Please try again.");
      }
    }
  };

  React.useEffect(() => {
    // Check localStorage for debug logs after redirect
    const debugInfo = localStorage.getItem("email_debug");
    if (debugInfo) {
      console.log("Debug Info after redirect:", debugInfo);
      // Clear localStorage debug info after showing it
      localStorage.removeItem("email_debug");
    }
  }, []);

  return (
    <div>
      <div className="row2" id="flex">
        <div className="main">
          <h2>Send Email</h2>
          <div>
            <label>
              <strong>Recipient Email:</strong>
            </label>
            <input
              type="email"
              value={recipient}
              onChange={(e) => setRecipient(e.target.value)}
              placeholder="Enter recipient email"
              required
            />
          </div>
          <div>
            <label>
              <strong>CC Email(s):</strong> (comma separated)
            </label>
            <input
              type="email"
              value={cc}
              onChange={(e) => setCc(e.target.value)}
              placeholder="Enter CC emails (optional)"
            />
          </div>
          <div>
            <label>
              <strong>Subject:</strong>
            </label>
            <input
              type="text"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              placeholder="Enter email subject"
              required
            />
          </div>
          <div>
            <label>
              <strong>Message:</strong>
            </label>
            <textarea
              value={body}
              onChange={(e) => setBody(e.target.value)}
              placeholder="Enter email content"
              rows="5"
              required
            />
          </div>
          <button onClick={sendEmail}>Send Email</button>
        </div>
      </div>
    </div>
  );
};

export default Email;
