import React, { useState, useEffect } from "react";
import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
  addDoc,
  setDoc,
  getDoc,
  doc,
} from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { db } from "../../firebase";
import { useAuth } from "../../AuthProvider";
import { Navbar, Footer, Side } from "../../components";
import { images } from "../../constants";
import Locationscroll from "../../components/Locationscroll/Locationscroll";
import { useNavigate, useParams } from "react-router-dom";
import "./Intranet.css";
import { v4 as uuidv4 } from "uuid";

const AddStudent = () => {
  const { isAdmin, userData } = useAuth();
  const [selectedClass, setSelectedClass] = useState();
  const [newStudentName, setNewStudentName] = useState("");
  const [gender, setGender] = useState();
  const [newStudentBirthday, setNewStudentBirthday] = useState();
  const [newStudentImage, setNewStudentImage] = useState(null);
  const [newStudentImage2, setNewStudentImage2] = useState(null);
  const [description, setDescription] = useState("");
  const [message, setMessage] = useState("");
  const [students, setStudents] = useState([]);
  const navigate = useNavigate();
  const { student } = useParams();

  useEffect(() => {
    const fetchStudents = async () => {
      try {
        const studentCollection = collection(db, "students");
        const studentSnapshot = await getDocs(studentCollection);
        const studentData = studentSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setStudents(studentData);
      } catch (error) {
        console.error("Error fetching students:", error);
      }
    };

    fetchStudents();
  }, []);

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    setNewStudentImage(file);
  };

  const handleImageUpload2 = async (e) => {
    const file = e.target.files[0];
    setNewStudentImage2(file);
  };

  const handleAddStudent = async () => {
    const storage = getStorage();
    const storageRef = ref(storage, `student_images/${newStudentImage.name}`);
    await uploadBytes(storageRef, newStudentImage);
    const imageUrl = await getDownloadURL(storageRef);

    const storageRef2 = ref(storage, `student_images/${newStudentImage2.name}`);
    await uploadBytes(storageRef2, newStudentImage2);
    const imageUrl2 = await getDownloadURL(storageRef2);

    const studentData = {
      id: uuidv4(),
      name: newStudentName,
      image: imageUrl,
      birthday: new Date(newStudentBirthday),
      gender: gender,
      description: description,
      image2: imageUrl2,
      message: message,
    };

    console.log("studentData", studentData);

    const db = getFirestore();
    const studentsCollectionRef = collection(db, "students");
    const groupDocRef = doc(studentsCollectionRef, selectedClass);
    const groupDocSnapshot = await getDoc(groupDocRef);

    if (groupDocSnapshot.exists()) {
      const groupData = groupDocSnapshot.data();
      const studentsList = groupData.students_list || [];
      const updatedStudentsList = [...studentsList, studentData];
      await setDoc(
        groupDocRef,
        { students_list: updatedStudentsList },
        { merge: true }
      );
    } else {
      await setDoc(groupDocRef, { students_list: [studentData] });
    }

    setNewStudentName("");
    setNewStudentImage(null);
  };

  const handleClassSelect = (event) => {
    setSelectedClass(event.target.value);
  };

  return (
    <div>
      <div className="text-container">
        <p>
          Student image:
          <div style={{ display: "flex", flexDirection: "column" }}>
            <input type="file" onChange={handleImageUpload} />
            {newStudentImage && (
              <img
                src={URL.createObjectURL(newStudentImage)}
                alt="New student"
                className="student-image"
                style={{ width: "200px", height: "200px" }}
              />
            )}
          </div>
        </p>
      </div>
      <div className="text-container">
        <p style={{ display: "flex" }}>
          Birthday:{" "}
          <input
            type="date"
            value={newStudentBirthday}
            onChange={(e) => setNewStudentBirthday(e.target.value)}
            placeholder="Enter student birthday"
            style={{ flex: "1" }}
          />
        </p>
      </div>
      <div className="text-container">
        <p style={{ display: "flex" }}>
          Student name:{" "}
          <input
            type="text"
            value={newStudentName}
            onChange={(e) => setNewStudentName(e.target.value)}
            placeholder="Enter student name"
            style={{ flex: "1" }}
          />
        </p>
      </div>
      <div className="text-container">
        <p style={{ display: "flex" }}>
          Gender:{" "}
          <select
            value={gender}
            onChange={(e) => setGender(e.target.value)}
            style={{ flex: "1" }}
          >
            <option value="" selected disabled>
              Select
            </option>
            <option value="Boy">Boy</option>
            <option value="Girl">Girl</option>
          </select>
        </p>
      </div>
      <div className="text-container">
        <p style={{ display: "flex" }}>
          Class:{" "}
          <select
            value={selectedClass}
            onChange={handleClassSelect}
            style={{ flex: "1" }}
          >
            <option value="" selected disabled>
              Select
            </option>
            {students.map((studentclass) => (
              <option key={studentclass.id} value={studentclass.id}>
                {studentclass.name}
              </option>
            ))}
          </select>
        </p>
      </div>
      <div className="text-container">
        <p>
          Description:{" "}
          <textarea
            type="text"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Write a description"
            style={{ border: "0.05em solid rgb(237, 237, 237)" }}
          />
        </p>
      </div>
      <div className="text-container">
        <p>
          Second image:
          <div style={{ display: "flex", flexDirection: "column" }}>
            <input type="file" onChange={handleImageUpload2} />
            {newStudentImage2 && (
              <img
                src={URL.createObjectURL(newStudentImage2)}
                alt="New student 2"
                className="student-image"
                style={{ width: "200px", height: "200px" }}
              />
            )}
          </div>
        </p>
      </div>
      <div className="text-container">
        <p>
          Message:{" "}
          <textarea
            type="text"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Write a message"
            style={{ border: "0.05em solid rgb(237, 237, 237)" }}
          />
        </p>
      </div>
      <br />
      <button onClick={handleAddStudent}>Add new student</button>
    </div>
  );
};

export default AddStudent;
