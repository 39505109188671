import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './articles.css';
import App from './App';
import { MsalProvider } from '@azure/msal-react';
import { msalInstance } from './msalConfig';
import { BrowserRouter } from 'react-router-dom';
import { ArticlesProvider } from './container/Articles/ArticlesContext';
import { WhatWeDoProvider } from './container/WhatWeDo/WhatWeDoContext';
import { UsersProvider } from './container/Auth/UsersContext';
import { AuthProvider } from './AuthProvider'; // Import the AuthProvider
import { EconomicsProvider } from "./container/Pages/Intranet/EconomicsContext";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <BrowserRouter>
      <AuthProvider> 
        <UsersProvider>
            <ArticlesProvider>
              <WhatWeDoProvider>
              <EconomicsProvider>
                <App />
                </EconomicsProvider>
              </WhatWeDoProvider>
            </ArticlesProvider> 
          </UsersProvider>
        </AuthProvider>
      </BrowserRouter>
    </MsalProvider>
  </React.StrictMode>
);
