import React from "react";
import { useAuthState } from "react-firebase-hooks/auth"; // If using react-firebase-hooks
import { auth } from "../../firebase";
import {
  Navbar,
  Footer,
  Side,
  Counter,
  Partners,
  Section,
  MyLink,
} from "../../components";
// import { articles } from "../../container/Articles/Articles";
import { useArticles } from "../Articles/ArticlesContext";
// import { is } from "immutable";

const Home = ({ isAdmin }) => {
  console.log("isAdmin in Home:", isAdmin);
  const [user] = useAuthState(auth); // Get the current user
  const { articles, articlesLoading } = useArticles();
  const publishedArticles = articles.filter(
    (article) => article.status === "Published"
  ).length;

  const truncateDescription = (description, maxLength = 380) => {
    if (description.length > maxLength) {
      // Find the last space within the 200 character limit
      const trimmedText = description.substring(0, maxLength);
      const lastSpaceIndex = trimmedText.lastIndexOf(" ");
      return description.substring(0, lastSpaceIndex) + "...";
    }
    return description;
  };

  return (
    <div>
      <Navbar isAdmin={isAdmin} />
      <div>
        <div className="row" id="flex">
          <Side />
          <div className="main" id="main">
            <div className="article">
              <div className="counter-parent">
                <Counter
                  header="Daily library users"
                  targetNumber={200}
                  icon='<i class="fa-solid fa-book"></i>'
                  description={
                    <>
                      More than 200 children have direct access to our library
                      on a daily basis.
                    </>
                  }
                  isAdmin={isAdmin}
                  firestorePath="counters/dailyLibraryUsers" // Specify the Firestore path
                />
                <Counter
                  header="Children impacted"
                  targetNumber={1400}
                  icon='<i class="fa-solid fa-hand-holding-medical"></i>'
                  description={
                    <>
                      The entire community utilizes and appreciates The Watoto
                      Library.
                    </>
                  }
                  isAdmin={isAdmin}
                  firestorePath="counters/childrenImpacted" // Specify the Firestore path
                />
                <Counter
                  header="Sponsored children"
                  targetNumber={46}
                  icon='<i class="fa-solid fa-school"></i>'
                  description={
                    <>
                      We have been able to send a great number of children to
                      school.
                    </>
                  }
                  isAdmin={isAdmin}
                  firestorePath="counters/sponsoredChildren" // Specify the Firestore path
                />
                <Counter
                  header="Meals served"
                  targetNumber={1800}
                  icon='<i class="fa-solid fa-utensils"></i>'
                  description={
                    <>
                      Through our programs, we have been able to feed many
                      children.
                    </>
                  }
                  isAdmin={isAdmin}
                  firestorePath="counters/mealsServed" // Specify the Firestore path
                />
              </div>
            </div>
            <br />
            <MyLink to="/articles">
              <h2 className="home-newsletter-header">
                <u>Newsletters</u>
              </h2>
            </MyLink>

            {articlesLoading ? (
              <div className="loading-container">
                <div className="loading-animation"></div>
                <div className="loading-text">Loading...</div>
              </div>
            ) : (
              <>
                {articles
                  .filter(
                    (article) => isAdmin || article.status === "Published"
                  )
                  .sort((a, b) => b.id - a.id)
                  .slice(0, 3)
                  .map((article) => (
                    <div key={article.id}>
                      <div className="article" id={article.id}>
                        <Section
                          articleId={article.id}
                          header={article.title}
                          day={article.day}
                          month={article.month}
                          year={article.year}
                          description={truncateDescription(article.description)}
                          img={article.img}
                          readMore="Read more"
                        />
                        {isAdmin && (
                          <div>
                            {article.status === "Draft" && (
                              <>
                                <br />
                                {article.status}{" "}
                                <i className="fa fa-pencil-alt"></i>
                              </>
                            )}
                          </div>
                        )}
                      </div>
                      <br />
                    </div>
                  ))}
                <MyLink to="/articles">
                  <div className="article-click goToNews">
                    <p className="readmore">
                      All Newsletters{" "}
                      <i className="fa-solid fa-arrow-right"></i>
                    </p>
                  </div>
                </MyLink>
              </>
            )}
          </div>
        </div>
        <Partners />
        <Footer />
      </div>
      {/* )} */}
    </div>
  );
};

export default Home;
