import React, { useState, useEffect } from "react";
import {
  getFirestore,
  collection,
  getDocs,
  addDoc,
  query,
  where,
  updateDoc,
  doc,
  deleteDoc,
} from "firebase/firestore";
import { useAuth } from "../../../../AuthProvider";
import "./Newsletters.css";

const Newsletters = () => {
  const db = getFirestore();
  const { isAdmin } = useAuth();
  const [templates, setTemplates] = useState([]);
  const [sponsors, setSponsors] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [selectedSponsor, setSelectedSponsor] = useState(null);
  const [templateContent, setTemplateContent] = useState("");
  const [templateName, setTemplateName] = useState("");
  const [customizedContent, setCustomizedContent] = useState("");
  const [copySuccess, setCopySuccess] = useState("");
  const [sendouts, setSendouts] = useState([]);
  const [currentSendout, setCurrentSendout] = useState(null);
  const [sendToAll, setSendToAll] = useState(true);
  const [selectedSendoutSponsors, setSelectedSendoutSponsors] = useState([]);
  const [editingTemplate, setEditingTemplate] = useState(false);
  const [sendoutDate, setSendoutDate] = useState("");
  const [editingContentIndex, setEditingContentIndex] = useState(null);

  useEffect(() => {
    fetchTemplates();
    fetchSponsors();
    fetchSendouts();
  }, []);

  const fetchTemplates = async () => {
    const templatesSnapshot = await getDocs(collection(db, "newsletters"));
    const templatesData = templatesSnapshot.docs
      .filter((doc) => doc.data().type === "template")
      .map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
    setTemplates(templatesData);
  };

  const fetchSponsors = async () => {
    const sponsorsRef = collection(db, "sponsorships");
    const sponsorsQuery = query(
      sponsorsRef,
      where("status", "in", ["Active", "Pending"])
    );
    const sponsorsSnapshot = await getDocs(sponsorsQuery);
    const sponsorsData = sponsorsSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    // Fetch sponsor user data
    const usersSnapshot = await getDocs(collection(db, "users"));
    const usersData = usersSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    // Merge sponsor data with user data
    const mergedSponsors = sponsorsData.map((sponsor) => {
      const user = usersData.find((user) => user.id === sponsor.sponsorId);
      return {
        ...sponsor,
        sponsorName: user ? user.name || user.phoneNumber : "Unknown",
      };
    });

    setSponsors(mergedSponsors);
  };

  const fetchSendouts = async () => {
    const sendoutsSnapshot = await getDocs(collection(db, "newsletters"));
    const sendoutsData = sendoutsSnapshot.docs
      .filter((doc) => doc.data().type === "sendout")
      .map((doc) => ({
        id: doc.id,
        ...doc.data(),
        sendoutDate: doc.data().sendoutDate || "no date", // If no sendoutDate, display "no date"
      }));

    // Sort sendouts by sendoutDate (descending)
    sendoutsData.sort((a, b) => (a.sendoutDate < b.sendoutDate ? 1 : -1));

    setSendouts(sendoutsData);
  };

  const handleSaveTemplate = async () => {
    if (!templateName || !templateContent) {
      alert("Please provide a name and content for the template.");
      return;
    }

    try {
      if (editingTemplate && selectedTemplate) {
        // Update existing template
        const templateDocRef = doc(db, "newsletters", selectedTemplate);
        await updateDoc(templateDocRef, {
          name: templateName,
          content: templateContent,
        });
        alert("Template updated successfully!");
      } else {
        // Create new template
        const docRef = await addDoc(collection(db, "newsletters"), {
          name: templateName,
          content: templateContent,
          type: "template",
          createdAt: new Date(),
        });
        setSelectedTemplate(docRef.id);
        alert("Template saved successfully!");
      }
      // Refresh templates
      fetchTemplates();
      // Reset form
      setEditingTemplate(false);
      setTemplateName("");
      setTemplateContent("");
    } catch (error) {
      console.error("Error saving template:", error);
      alert("Error saving template.");
    }
  };

  const handleEditTemplate = () => {
    if (!selectedTemplate) {
      alert("Please select a template to edit.");
      return;
    }
    const template = templates.find((t) => t.id === selectedTemplate);
    if (template) {
      setEditingTemplate(true);
      setTemplateName(template.name);
      setTemplateContent(template.content);
    }
  };

  const handleDeleteSendout = async () => {
    if (!currentSendout) {
      alert("No sendout selected.");
      return;
    }
    const anySent = currentSendout.recipients.some((r) => r.sent);
    if (anySent) {
      alert("Cannot delete a sendout with sent newsletters.");
      return;
    }
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this sendout?"
    );
    if (confirmDelete) {
      try {
        await deleteDoc(doc(db, "newsletters", currentSendout.id));
        // Remove from state
        setSendouts(sendouts.filter((s) => s.id !== currentSendout.id));
        setCurrentSendout(null);
        alert("Sendout deleted successfully!");
      } catch (error) {
        console.error("Error deleting sendout:", error);
        alert("Error deleting sendout.");
      }
    }
  };

  const handleGenerateContent = () => {
    if (!selectedTemplate || !selectedSponsor) {
      alert("Please select a template and a sponsor.");
      return;
    }

    const sponsor = sponsors.find((s) => s.id === selectedSponsor);
    const sponsorName = sponsor ? sponsor.sponsorName : "Unknown";
    const studentName = sponsor.studentName || "Student";

    // Fetch the selected template content
    const template = templates.find((t) => t.id === selectedTemplate);
    const templateContent = template ? template.content : "";

    // Replace variables in the template content
    let content = templateContent;
    content = content.replace(/{sponsorName}/g, sponsorName);
    content = content.replace(/{studentName}/g, studentName);

    setCustomizedContent(content);
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(customizedContent).then(
      function () {
        setCopySuccess("Copied!");
        setTimeout(() => setCopySuccess(""), 2000);
      },
      function (err) {
        console.error("Async: Could not copy text: ", err);
      }
    );
  };

  const handleCreateSendout = async () => {
    if (!selectedTemplate) {
      alert("Please select a template.");
      return;
    }

    if (!sendoutDate) {
      alert("Please select a date for the sendout.");
      return;
    }

    let recipients = [];

    if (sendToAll) {
      // Send to all sponsors
      recipients = sponsors.map((sponsor) => {
        const sponsorName = sponsor.sponsorName;
        const studentName = sponsor.studentName || "Student";

        // Fetch the selected template content
        const template = templates.find((t) => t.id === selectedTemplate);
        const templateContent = template ? template.content : "";

        // Replace variables in the template content
        let content = templateContent;
        content = content.replace(/{sponsorName}/g, sponsorName);
        content = content.replace(/{studentName}/g, studentName);

        return {
          sponsorshipId: sponsor.id,
          sponsorName: sponsorName,
          studentName: studentName,
          content: content,
          sent: false,
          sentDate: null,
        };
      });
    } else {
      // Send to selected sponsors
      if (selectedSendoutSponsors.length === 0) {
        alert("Please select at least one sponsor.");
        return;
      }
      recipients = sponsors
        .filter((sponsor) => selectedSendoutSponsors.includes(sponsor.id))
        .map((sponsor) => {
          const sponsorName = sponsor.sponsorName;
          const studentName = sponsor.studentName || "Student";

          // Fetch the selected template content
          const template = templates.find((t) => t.id === selectedTemplate);
          const templateContent = template ? template.content : "";

          // Replace variables in the template content
          let content = templateContent;
          content = content.replace(/{sponsorName}/g, sponsorName);
          content = content.replace(/{studentName}/g, studentName);

          return {
            sponsorshipId: sponsor.id,
            sponsorName: sponsorName,
            studentName: studentName,
            content: content,
            sent: false,
            sentDate: null,
          };
        });
    }

    const sendoutData = {
      templateId: selectedTemplate,
      recipients: recipients,
      createdAt: new Date(),
      sendoutDate: sendoutDate, // Include sendoutDate
      type: "sendout",
    };

    try {
      const docRef = await addDoc(collection(db, "newsletters"), sendoutData);
      const newSendout = { id: docRef.id, ...sendoutData };
      setSendouts((prevSendouts) => [...prevSendouts, newSendout]);
      setCurrentSendout(newSendout);
      alert("Sendout created successfully!");
    } catch (error) {
      console.error("Error creating sendout:", error);
      alert("Error creating sendout.");
    }
  };

  const handleEditRecipientContent = async (recipientIndex, newContent) => {
    // Update the recipient's content
    const updatedRecipients = [...currentSendout.recipients];
    updatedRecipients[recipientIndex].content = newContent;

    // Update Firestore
    const sendoutDocRef = doc(db, "newsletters", currentSendout.id);
    await updateDoc(sendoutDocRef, { recipients: updatedRecipients });

    // Update state
    setCurrentSendout((prevSendout) => ({
      ...prevSendout,
      recipients: updatedRecipients,
    }));
  };

  const handleCopyRecipientContent = (recipientIndex) => {
    const recipient = currentSendout.recipients[recipientIndex];
    navigator.clipboard.writeText(recipient.content).then(
      function () {
        setCopySuccess("Copied!");
        setTimeout(() => setCopySuccess(""), 2000);
      },
      function (err) {
        console.error("Async: Could not copy text: ", err);
      }
    );
  };

  const handleMarkAsSent = async (recipientIndex) => {
    // Update the recipient's 'sent' status and sentDate
    const updatedRecipients = [...currentSendout.recipients];
    updatedRecipients[recipientIndex].sent = true;
    updatedRecipients[recipientIndex].sentDate = new Date()
      .toISOString()
      .split("T")[0];

    // Update the sendout in Firestore
    const sendoutDocRef = doc(db, "newsletters", currentSendout.id);
    await updateDoc(sendoutDocRef, { recipients: updatedRecipients });

    // Update the state
    setCurrentSendout((prevSendout) => ({
      ...prevSendout,
      recipients: updatedRecipients,
    }));

    // Optionally, check if all recipients are sent
    const allSent = updatedRecipients.every((recipient) => recipient.sent);
    if (allSent) {
      alert("All newsletters have been sent!");
    }
  };

  return (
    <div className="newsletters-page">
      <h2 className="newsletters-title">Newsletters</h2>

      <div className="newsletter-section create-template-section">
        <h3>
          {editingTemplate
            ? "Edit Newsletter Template"
            : "Create Newsletter Template"}
        </h3>
        <div className="variables-info">
          <p>
            <strong>Available Variables:</strong> {`{sponsorName}`},{" "}
            {`{studentName}`}
          </p>
        </div>
        <div className="form-group">
          <label>Select Template to Edit:</label>
          <select
            value={selectedTemplate || ""}
            onChange={(e) => setSelectedTemplate(e.target.value)}
            className="newsletter-select"
          >
            <option value="">Select a template</option>
            {templates.map((template) => (
              <option key={template.id} value={template.id}>
                {template.name}
              </option>
            ))}
          </select>
          <button onClick={handleEditTemplate} className="newsletter-button">
            Edit Template
          </button>
        </div>
        <input
          type="text"
          placeholder="Template Name"
          value={templateName}
          onChange={(e) => setTemplateName(e.target.value)}
          className="newsletter-input"
        />
        <textarea
          placeholder="Template Content"
          value={templateContent}
          onChange={(e) => setTemplateContent(e.target.value)}
          className="newsletter-textarea"
        ></textarea>
        <button onClick={handleSaveTemplate} className="newsletter-button">
          {editingTemplate ? "Update Template" : "Save Template"}
        </button>
      </div>

      <div className="newsletter-section newsletter-sendout-section">
        <h3>Newsletter Sendout</h3>
        <div className="form-group">
          <label>Select Template:</label>
          <select
            value={selectedTemplate || ""}
            onChange={(e) => setSelectedTemplate(e.target.value)}
            className="newsletter-select"
          >
            <option value="">Select a template</option>
            {templates.map((template) => (
              <option key={template.id} value={template.id}>
                {template.name}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label>Sendout Date:</label>
          <input
            type="date"
            value={sendoutDate}
            onChange={(e) => setSendoutDate(e.target.value)}
            className="newsletter-input"
          />
        </div>
        <div className="form-group">
          <label>Recipients:</label>
          <div>
            <label>
              <input
                type="radio"
                name="recipientOption"
                value="all"
                checked={sendToAll}
                onChange={() => setSendToAll(true)}
              />
              Send to all sponsors
            </label>
            <label>
              <input
                type="radio"
                name="recipientOption"
                value="selected"
                checked={!sendToAll}
                onChange={() => setSendToAll(false)}
              />
              Select sponsors
            </label>
          </div>
          {!sendToAll && (
            <select
              multiple
              value={selectedSendoutSponsors}
              onChange={(e) =>
                setSelectedSendoutSponsors(
                  Array.from(e.target.selectedOptions, (option) => option.value)
                )
              }
              className="newsletter-select"
            >
              {sponsors.map((sponsor) => (
                <option key={sponsor.id} value={sponsor.id}>
                  {sponsor.sponsorName} - {sponsor.studentName || "No student"}
                </option>
              ))}
            </select>
          )}
        </div>
        <button onClick={handleCreateSendout} className="newsletter-button">
          Create Sendout
        </button>
      </div>

      {sendouts.length > 0 && (
        <div className="newsletter-section select-sendout-section">
          <h3>Select a Sendout</h3>
          <select
            value={currentSendout ? currentSendout.id : ""}
            onChange={(e) => {
              const selectedSendout = sendouts.find(
                (sendout) => sendout.id === e.target.value
              );
              setCurrentSendout(selectedSendout);
              setEditingContentIndex(null); // Reset editing index
            }}
            className="newsletter-select"
          >
            <option value="">Select a sendout</option>
            {sendouts.map((sendout) => {
              const template = templates.find(
                (t) => t.id === sendout.templateId
              );
              const templateName = template
                ? template.name
                : "Unknown Template";
              return (
                <option key={sendout.id} value={sendout.id}>
                  {templateName} - {sendout.sendoutDate}
                </option>
              );
            })}
          </select>
          {currentSendout && (
            <button
              onClick={handleDeleteSendout}
              className="newsletter-button delete-button"
            >
              Delete Sendout
            </button>
          )}
        </div>
      )}

      {currentSendout && (
        <div className="newsletter-section sendout-progress-section">
          <h3>Sendout Progress</h3>
          <table className="newsletter-table">
            <thead>
              <tr>
                <th>Sponsor Name</th>
                <th>Student Name</th>
                <th>Status</th>
                <th>Content</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {currentSendout.recipients.map((recipient, index) => (
                <tr key={recipient.sponsorshipId}>
                  <td>{recipient.sponsorName}</td>
                  <td>{recipient.studentName}</td>
                  <td>
                    {recipient.sent
                      ? `Sent on ${recipient.sentDate}`
                      : "Not Sent"}
                  </td>
                  <td>
                    {editingContentIndex === index ? (
                      <textarea
                        value={recipient.content || ""}
                        onChange={(e) =>
                          handleEditRecipientContent(index, e.target.value)
                        }
                        onBlur={() => setEditingContentIndex(null)}
                        className="newsletter-textarea"
                      ></textarea>
                    ) : (
                      <div
                        className="content-preview"
                        onClick={() => setEditingContentIndex(index)}
                      >
                        {recipient.content
                          ? `${recipient.content.substring(0, 100)}...`
                          : "No content available"}
                      </div>
                    )}
                  </td>

                  <td>
                    <button
                      onClick={() => handleCopyRecipientContent(index)}
                      className="newsletter-button"
                    >
                      Copy Content
                    </button>
                    {!recipient.sent && (
                      <button
                        onClick={() => handleMarkAsSent(index)}
                        className="newsletter-button"
                      >
                        Mark as Sent
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {copySuccess && <span className="copy-success">{copySuccess}</span>}
    </div>
  );
};

export default Newsletters;
