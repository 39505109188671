import { PublicClientApplication } from "@azure/msal-browser";

const msalConfig = {
  auth: {
    clientId: "7f465f11-3205-4247-8c42-6cc183d08a2a", // Your client ID from Azure
    authority: `https://login.microsoftonline.com/e6b567ff-b030-44bc-a96e-dc38e8629d29`, // Your tenant ID
    redirectUri: "http://localhost:3000/", 
  },
};

export const msalInstance = new PublicClientApplication(msalConfig);