import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { doc, getDoc, updateDoc, serverTimestamp } from "firebase/firestore"; // Firestore functions
import { db } from "../../../firebase"; // Firestore config
import { Navbar, Footer } from "../../../components";
import Locationscroll from "../../../components/Locationscroll/Locationscroll";
import { useAuth } from "../../../AuthProvider";
import ReactQuill from "react-quill"; // Import Quill editor
import "react-quill/dist/quill.snow.css"; // Import Quill styles
import "./Reports.css";

const Report = () => {
  const { isAdmin, userData } = useAuth();
  const { id } = useParams(); // Get report ID from the URL
  const navigate = useNavigate();

  const [reportContent, setReportContent] = useState("");
  const [week, setWeek] = useState("");
  const [status, setStatus] = useState("draft");
  const [feedback, setFeedback] = useState("");
  const [feedbackBy, setFeedbackBy] = useState("");
  const [feedbackOn, setFeedbackOn] = useState("");
  const [submittedAt, setSubmittedAt] = useState(null);
  const [userName, setUserName] = useState("");
  const [draftSaved, setDraftSaved] = useState(false);
  const [feedbackSaved, setFeedbackSaved] = useState(false);
  const [loading, setLoading] = useState(true);
  const [hasAccess, setHasAccess] = useState(true);
  const [feedbackConfirmedBy, setFeedbackConfirmedBy] = useState("");
  const [feedbackConfirmedOn, setFeedbackConfirmedOn] = useState("");

  useEffect(() => {
    const fetchReport = async () => {
      try {
        if (!userData || !id) {
          navigate("/intranet#reports");
          return;
        }

        const reportRef = doc(db, "reports", id);
        const reportSnap = await getDoc(reportRef);

        if (reportSnap.exists()) {
          const reportData = reportSnap.data();

          // If the user is an admin, allow access without checking userId
          if (userData.role === "Admin") {
            setHasAccess(true); // Admin always has access
          } else if (reportData.userId === userData.email) {
            // If the user is not admin but the report belongs to them, allow access
            setHasAccess(true);
          } else {
            // Otherwise, deny access
            setHasAccess(false);
            setTimeout(() => {
              navigate("/intranet#reports");
            }, 2000);
            return; // Return early to avoid setting report data for unauthorized access
          }

          const feedbackOnDate = reportData.feedbackOn
            ? reportData.feedbackOn.toDate().toLocaleString()
            : "";

          const feedbackConfirmedOnDate = reportData.feedbackConfirmedOn
            ? reportData.feedbackConfirmedOn.toDate().toLocaleString()
            : "";

          // Set state with fetched data
          setReportContent(reportData.content || "");
          setWeek(reportData.week || "");
          setStatus(reportData.status || "draft");
          setFeedback(reportData.feedback || "");
          setFeedbackBy(reportData.feedbackBy || "");
          setFeedbackOn(feedbackOnDate || "");
          setUserName(reportData.userName || "Unknown");
          setSubmittedAt(reportData.submittedAt?.toDate() || null);
          setFeedbackConfirmedBy(reportData.feedbackConfirmedBy || "");
          setFeedbackConfirmedOn(feedbackConfirmedOnDate || "");
        } else {
          console.log("No such report!");
          navigate("/intranet/reports");
        }
      } catch (error) {
        console.error("Error fetching report:", error);
        navigate("/intranet#reports");
      } finally {
        setLoading(false);
      }
    };

    fetchReport();
  }, [id, userData, isAdmin, navigate]);

  const handleSubmit = async () => {
    try {
      const reportRef = doc(db, "reports", id);
      await updateDoc(reportRef, {
        content: reportContent,
        status: "submitted",
        submittedAt: serverTimestamp(),
      });

      setStatus("submitted");
      setTimeout(() => {
        navigate("/intranet#reports");
      }, 1000);
    } catch (error) {
      console.error("Error submitting report:", error);
    }
  };

  const handleConfirmFeedback = async () => {
    try {
      const reportRef = doc(db, "reports", id);
      await updateDoc(reportRef, {
        status: "completed", // Update status to completed
        feedbackConfirmedBy: userData.name, // The user confirming the feedback
        feedbackConfirmedOn: serverTimestamp(), // The current timestamp when feedback is confirmed
      });

      setStatus("completed");
      setTimeout(() => {
        navigate("/intranet#reports");
      }, 1000);
    } catch (error) {
      console.error("Error confirming feedback:", error);
    }
  };

  const handleSaveDraft = async () => {
    try {
      const reportRef = doc(db, "reports", id);
      await updateDoc(reportRef, {
        content: reportContent,
        status: "draft",
      });

      setDraftSaved(true);
      setTimeout(() => setDraftSaved(false), 2000);
    } catch (error) {
      console.error("Error saving draft:", error);
    }
  };

  const handleSaveFeedback = async () => {
    try {
      const reportRef = doc(db, "reports", id);
      await updateDoc(reportRef, {
        feedback, // The feedback text
        feedbackBy: userData.name, // The name of the user providing the feedback
        feedbackOn: serverTimestamp(), // The current timestamp
        status: "awaitingRead", // Update the status
      });
      setFeedbackSaved(true);
      setTimeout(() => setFeedbackSaved(false), 2000);
      navigate("/intranet#reports");
    } catch (error) {
      console.error("Error saving feedback:", error);
    }
  };

  const handleGoBack = () => {
    navigate("/intranet#reports");
  };

  const renderStaffView = () => (
    <>
      <div className="button-group">
        <button className="go-back-btn" onClick={handleGoBack}>
          Go Back
        </button>
        {status !== "submitted" && (
          <>
            {status === "draft" && (
              <>
                {" "}
                <button
                  className="orange-btn"
                  onClick={handleSaveDraft}
                  disabled={!reportContent || status === "submitted"}
                >
                  Save Draft
                </button>
                <button
                  className="submit-report-btn"
                  onClick={handleSubmit}
                  disabled={!reportContent || status === "submitted"}
                >
                  Submit Report
                </button>
              </>
            )}
            {status === "submitted" && "Awaiting feedback"}
            {status === "awaitingRead" && (
              <button
                className="submit-report-btn"
                onClick={handleConfirmFeedback}
              >
                Confirm Read Feedback
              </button>
            )}
          </>
        )}
      </div>
      <br />
      {status === "completed" && feedbackConfirmedBy && feedbackConfirmedOn && (
        <>
          <p>
            <b>
              <i class="fa-regular fa-circle-check"></i> Feedback confirmed by
            </b>{" "}
            {feedbackConfirmedBy} on {feedbackConfirmedOn}
          </p>
        </>
      )}
      {feedback && (
        <>
          <p>
            <b>
              <i class="fa-regular fa-comment"></i> Feedback submitted by
            </b>{" "}
            {feedbackBy} on {feedbackOn}
          </p>
          <br />
          <div
            className="report-content"
            dangerouslySetInnerHTML={{ __html: feedback }}
          />
        </>
      )}

      {status === "draft" ? (
        <>
          <ReactQuill
            theme="snow"
            value={reportContent}
            onChange={setReportContent}
            placeholder="Write your report here..."
            style={{ height: "450px" }}
          />
          <br />
        </>
      ) : (
        <>
          {submittedAt && (
            <>
              <br />
              <p>
                <b>
                  <i class="fa-regular fa-folder-open"></i> Report submitted by
                </b>{" "}
                {userName} on {submittedAt.toLocaleString()}
              </p>
            </>
          )}
          <br />
          <div
            className="report-content"
            dangerouslySetInnerHTML={{ __html: reportContent }}
          />
        </>
      )}
    </>
  );

  const renderAdminView = () => (
    <>
      <div className="button-group">
        <button className="go-back-btn" onClick={handleGoBack}>
          Go Back
        </button>
        {status !== "awaitingRead" &&
          status !== "completed" &&
          status !== "draft" && (
            <button
              className="submit-report-btn"
              onClick={handleSaveFeedback}
              disabled={!feedback.trim()}
            >
              Save Admin Feedback
            </button>
          )}
      </div>
      <div className="button-group"></div>
      {status !== "completed" &&
        status !== "draft" &&
        status !== "awaitingRead" && (
          <div className="admin-feedback-section">
            <br />
            <ReactQuill
              theme="snow"
              value={feedback}
              onChange={setFeedback}
              placeholder="Write feedback on the report here."
              style={{ height: "150px" }}
            />
            <br />
            <br />
          </div>
        )}
      <br />
      {status === "completed" && feedbackConfirmedBy && feedbackConfirmedOn && (
        <>
          <p>
            <b>
              <i class="fa-regular fa-circle-check"></i> Feedback confirmed by
            </b>{" "}
            {feedbackConfirmedBy} on {feedbackConfirmedOn}
          </p>
        </>
      )}
      {(status === "awaitingRead" || status === "completed") && (
        <>
          <p>
            <b>
              <i class="fa-regular fa-comment"></i> Feedback submitted by
            </b>{" "}
            {feedbackBy} on {feedbackOn}
          </p>
          <br />
          <div
            className="report-content"
            dangerouslySetInnerHTML={{ __html: feedback }}
          />
        </>
      )}

      <br />
      <p>
        {status !== "draft" && (
          <p>
            <b>
              <i class="fa-regular fa-folder-open"></i> Report submitted by
            </b>{" "}
            {userName} on {submittedAt.toLocaleString()}
          </p>
        )}
        {status === "draft" && <span className="orange">Saved as draft.</span>}
      </p>
      <br />

      <div
        className="report-content"
        dangerouslySetInnerHTML={{ __html: reportContent }}
      />
    </>
  );

  return (
    <>
      <Locationscroll />
      <Navbar isAdmin={isAdmin} />
      <div className="row2" id="flex">
        <div className="main">
          <div className="article newsletters">
            <div>
              {loading ? (
                <div>Loading...</div>
              ) : !hasAccess ? (
                <div className="no-access-container">
                  <h2>No Access</h2>
                  <p>
                    You do not have permission to view this report.
                    Redirecting...
                  </p>
                </div>
              ) : (
                <div className="report-page">
                  <h2>Report for {week}</h2>
                  <p>Report by {userName}</p>
                  <br />
                  {draftSaved && (
                    <p className="draft-saved-message">
                      Draft successfully saved!
                    </p>
                  )}
                  {feedbackSaved && (
                    <p className="draft-saved-message">
                      Feedback successfully saved!
                    </p>
                  )}
                  {isAdmin ? renderAdminView() : renderStaffView()}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Report;
