import React, { useState } from "react";
import "./SponsorForm.css";

const SponsorForm = ({ firstName, random }) => {
  return (
    <div id="sponsor-form">
      <h3>Å sponse et barn koster 300 kr i måneden.</h3>
      <p>
        Vi tilbyr faste avdrag med Vipps eller nettbank. <br />
        For nettbankbetaling eller ved ønske om andre alternativer, send en mail
        til{" "}
        <u>
          <a href="mailto:magnus@thewatotolibrary.org">
            magnus@thewatotolibrary.org
          </a>
        </u>
        .
      </p>
      <p>
        Å bli sponsor for {firstName} betyr å gi en mulighet til et barn som
        ikke har noen framtid foran seg uten din støtte. <br />
        Tusen takk for ditt bidrag.
      </p>
      <br />
      {!random && (
        <h3 className="noMarginBottom">
          Fyll inn skjemaet under eller bruk knappen for å sponse {firstName}
        </h3>
      )}
      <br />
      <b>Kortbetaling med Stripe: </b>
      <a
        className="button-design paypal"
        id="stripe"
        href="https://buy.stripe.com/fZe8zs4xn9OTfrabIO"
        target="_blank"
      >
        Trykk her for å betale med Stripe
      </a>
      <br />
      <br />
      <b>Eller med Vipps: </b>

      <p className="smallTxt">
        Trykk på "Bytt til foretak" for å sponse gjennom bedrift.
      </p>
      <iframe
        width="100%"
        style={{ height: "1000px", border: "none", marginTop: "0" }}
        src="https://www4.solidus.no/SolidusUser/placeorder/9E871571-9F84-4AC0-8D2C-D051E8F5C28D"
      ></iframe>
    </div>
  );
};

export default SponsorForm;
