import React, { useState, useEffect, useRef } from "react";
import {
  getFirestore,
  collection,
  getDocs,
  doc,
  updateDoc,
} from "firebase/firestore";
import Locationscroll from "../../../components/Locationscroll/Locationscroll";
import { useAuth } from "../../../AuthProvider";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaCalendarAlt } from "react-icons/fa";
import "./StudentAttendance.css"; // Add your custom CSS

const daysOfWeek = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];
const attendanceOptions = ["Present", "Not Present", "Excused", "Late"];

const StudentAttendance = () => {
  const { isAdmin, userData } = useAuth();
  const [loading, setLoading] = useState(true);
  const [studentClasses, setStudentClasses] = useState([]);
  const [selectedClass, setSelectedClass] = useState("");
  const [students, setStudents] = useState([]);
  const [attendance, setAttendance] = useState({});
  const [currentWeek, setCurrentWeek] = useState(new Date());
  const [subjects, setSubjects] = useState([]);
  const [lessons, setLessons] = useState({}); // Store lessons by day
  const [newLesson, setNewLesson] = useState({
    subjectId: "",
    day: "",
    startTime: "",
    endTime: "",
  });
  const [markAllPresent, setMarkAllPresent] = useState(true); // Default to true for marking all present
  const [showDropdowns, setShowDropdowns] = useState({});
  const [confirmedDays, setConfirmedDays] = useState({});
  const [editingLessonId, setEditingLessonId] = useState(null); // Track which lesson is being edited

  const dropdownRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!userData) {
          setLoading(true);
          return;
        }

        const db = getFirestore();

        // Fetch student classes (assuming no role-based restriction here)
        const studentsCollection = collection(db, "students");
        const classSnapshot = await getDocs(studentsCollection);
        const classes = classSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setStudentClasses(classes);

        // Fetch subjects
        const subjectsCollection = collection(db, "subjects");
        const subjectsSnapshot = await getDocs(subjectsCollection);
        const subjectsData = subjectsSnapshot.docs.map((doc) => ({
          id: doc.id,
          name: doc.data().name,
          abbreviation: doc.data().abbreviation,
          teacherId: doc.data().teacherId,
        }));
        setSubjects(subjectsData);

        setLoading(false); // Set loading to false after fetching data
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [userData]);

  useEffect(() => {
    const savedClass = localStorage.getItem("selectedClass");
    if (savedClass) {
      setSelectedClass(savedClass);
    }
  }, []);

  // New useEffect to call fetchStudentsForClass after both studentClasses and selectedClass are available
  useEffect(() => {
    if (selectedClass && studentClasses.length > 0) {
      fetchStudentsForClass(selectedClass);
    }
  }, [selectedClass, studentClasses]); // Watch both selectedClass and studentClasses

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdowns({});
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const fetchStudentsForClass = async (classId) => {
    const selectedClassData = studentClasses.find(
      (studentClass) => studentClass.id === classId
    );

    // Check if the selected class data exists
    if (!selectedClassData) {
      console.error("Selected class data not found.");
      return;
    }

    setStudents(selectedClassData.students_list || []);

    const initialAttendance = {};
    selectedClassData.students_list.forEach((student) => {
      initialAttendance[student.id] = student.attendance || {};
    });
    setAttendance(initialAttendance);
    setLessons(selectedClassData.lessons || {});
    setConfirmedDays(selectedClassData.confirmedDays || {});
  };

  const handleLessonTimeChange = (lessonId, key, value) => {
    setLessons((prevLessons) => {
      const updatedLessons = { ...prevLessons };
      Object.keys(updatedLessons).forEach((day) => {
        updatedLessons[day] = updatedLessons[day].map((lesson) =>
          lesson.lessonId === lessonId ? { ...lesson, [key]: value } : lesson
        );
      });
      return updatedLessons;
    });
  };

  const handleLessonSubjectChange = (lessonId, subjectId) => {
    setLessons((prevLessons) => {
      const updatedLessons = { ...prevLessons };
      Object.keys(updatedLessons).forEach((day) => {
        updatedLessons[day] = updatedLessons[day].map((lesson) =>
          lesson.lessonId === lessonId ? { ...lesson, subjectId } : lesson
        );
      });
      return updatedLessons;
    });
  };

  const saveUpdatedLessons = async () => {
    const db = getFirestore();
    const classDocRef = doc(db, "students", selectedClass);

    await updateDoc(classDocRef, {
      lessons,
    });
  };

  const handleAttendanceChange = async (studentId, day, lessonId, status) => {
    const updatedAttendance = {
      ...attendance,
      [studentId]: {
        ...attendance[studentId],
        [day]: {
          ...attendance[studentId]?.[day],
          [lessonId]: status,
        },
      },
    };

    setAttendance(updatedAttendance);

    setShowDropdowns((prev) => ({
      ...prev,
      [`${studentId}-${lessonId}`]: false,
    }));

    // Automatically save the updated attendance
    const db = getFirestore();
    const classDocRef = doc(db, "students", selectedClass);

    const updatedStudents = students.map((student) => ({
      ...student,
      attendance: {
        ...student.attendance,
        ...updatedAttendance[student.id],
      },
    }));

    await updateDoc(classDocRef, {
      students_list: updatedStudents,
    });
  };

  const handleToggleDropdown = (studentId, lessonId, day) => {
    // Only allow toggling the dropdown if the day is not confirmed
    if (!confirmedDays[day]) {
      setShowDropdowns((prev) => ({
        ...prev,
        [`${studentId}-${lessonId}`]: !prev[`${studentId}-${lessonId}`],
      }));
    }
  };

  const getIcon = (status) => {
    switch (status) {
      case "Present":
        return "✔️";
      case "Not Present":
        return "❌";
      case "Excused":
        return "🟡";
      case "Late":
        return "⏰";
      default:
        return "";
    }
  };

  const handlePreviousWeek = () => {
    const previousWeek = new Date(currentWeek);
    previousWeek.setDate(previousWeek.getDate() - 7);

    setCurrentWeek(previousWeek);
  };

  const handleNextWeek = () => {
    const nextWeek = new Date(currentWeek);
    nextWeek.setDate(nextWeek.getDate() + 7);

    const today = new Date();
    const norwayOffset = new Date().getTimezoneOffset() + 120; // Adjust for Norwegian time zone
    today.setMinutes(today.getMinutes() + norwayOffset); // Adjust for Norwegian time zone

    const endOfCurrentWeek = new Date(today);
    endOfCurrentWeek.setDate(today.getDate() - today.getDay() + 7); // End of the current week (Sunday)

    // Allow navigation to next week only if it doesn't surpass the current day
    if (nextWeek <= endOfCurrentWeek && nextWeek <= today) {
      setCurrentWeek(nextWeek);
    }
  };

  const isNextWeekDisabled = () => {
    const today = new Date();
    const norwayOffset = new Date().getTimezoneOffset() + 120; // Norway is UTC+2 during daylight saving, UTC+1 otherwise
    today.setMinutes(today.getMinutes() + norwayOffset); // Adjust for Norwegian time zone

    const endOfCurrentWeek = new Date(today);
    endOfCurrentWeek.setDate(today.getDate() - today.getDay() + 7); // End of the current week (Sunday)

    const startOfNextWeek = new Date(currentWeek);
    startOfNextWeek.setDate(currentWeek.getDate() + 7); // Start of the next week

    return startOfNextWeek > endOfCurrentWeek || startOfNextWeek > today; // Disable if next week is past today
  };

  const handleWeekChange = (date) => {
    const startOfWeek = new Date(
      date.setDate(date.getDate() - date.getDay() + 1)
    );
    setCurrentWeek(startOfWeek);
  };

  const getStartOfWeek = () => {
    const startOfWeek = new Date(currentWeek);
    startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay() + 1);
    return startOfWeek;
  };

  const getEndOfWeek = () => {
    const endOfWeek = new Date(currentWeek);
    endOfWeek.setDate(endOfWeek.getDate() - endOfWeek.getDay() + 7);
    return endOfWeek;
  };

  const getWeekNumber = (date) => {
    const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
    const daysOffset = (firstDayOfYear.getDay() + 6) % 7; // Monday as the first day of the week
    const diff = date - firstDayOfYear + 24 * 60 * 60 * 1000 * daysOffset;
    const weekNumber = Math.ceil(diff / (7 * 24 * 60 * 60 * 1000));
    return weekNumber;
  };

  const handleLessonCreation = async () => {
    const { day, subjectId, startTime, endTime } = newLesson;

    if (!day || !subjectId || !startTime || !endTime) {
      alert(
        "Please fill in all the required fields: day, subject, start time, and end time."
      );
      return;
    }

    const isConfirmed = window.confirm(
      `Confirm that you want to create a new lesson on ${day} from ${startTime} to ${endTime}?`
    );

    // If user cancels, do nothing
    if (!isConfirmed) {
      return;
    }

    const lessonId = `${subjectId}-${startTime}-${endTime}`;

    // Add the lesson
    const updatedLessons = {
      ...lessons,
      [day]: [
        ...(lessons[day] || []),
        {
          lessonId,
          subjectId,
          startTime,
          endTime,
        },
      ],
    };
    setLessons(updatedLessons);

    // Automatically set all students to "Present" by default
    const updatedAttendance = { ...attendance };
    students.forEach((student) => {
      updatedAttendance[student.id] = {
        ...updatedAttendance[student.id],
        [day]: {
          ...updatedAttendance[student.id]?.[day],
          [lessonId]: "Present", // Set default status as "Present"
        },
      };
    });
    setAttendance(updatedAttendance);

    // Save updated attendance and lessons to Firestore
    const db = getFirestore();
    const classDocRef = doc(db, "students", selectedClass);
    const updatedStudents = students.map((student) => ({
      ...student,
      attendance: {
        ...student.attendance,
        ...updatedAttendance[student.id],
      },
    }));

    await updateDoc(classDocRef, {
      students_list: updatedStudents,
      lessons: updatedLessons,
    });

    // Clear the new lesson fields
    setNewLesson({ subjectId: "", day: "", startTime: "", endTime: "" });
  };

  const CalendarIconInput = ({ value, onClick }) => (
    <span className="calendar-icon-input" onClick={onClick}>
      <FaCalendarAlt size={20} style={{ cursor: "pointer" }} />
    </span>
  );

  const handleConfirmDay = async (day) => {
    const dayLessons = lessons[day] || [];
    let subjectsWithoutAttendance = new Set();

    // Check if all students have a status for each lesson
    for (const student of students) {
      for (const lesson of dayLessons) {
        const lessonId = lesson.lessonId;
        const studentAttendance = attendance[student.id]?.[day]?.[lessonId];

        if (!studentAttendance) {
          const subject = subjects.find((sub) => sub.id === lesson.subjectId);
          if (subject) {
            subjectsWithoutAttendance.add(subject.name);
          }
        }
      }
    }

    // If there are any subjects without attendance, show a message and return
    if (subjectsWithoutAttendance.size > 0) {
      const subjectText =
        subjectsWithoutAttendance.size > 1 ? "subjects" : "subject";
      alert(
        `You have students without registered attendance for the ${subjectText}: ${[
          ...subjectsWithoutAttendance,
        ].join(", ")}. Please ensure all students have a status.`
      );
      return; // Exit if any student is missing a status
    }

    // Handle case where there are no lessons registered for the day
    if (dayLessons.length === 0) {
      if (window.confirm("Confirm that there were no lessons today.")) {
        const confirmedBy = userData.name || userData.email || "Unknown User"; // Fallback to "Unknown User" if neither is available
        const confirmedAt = new Date(); // Store the actual Date object

        const updatedConfirmedDays = {
          ...confirmedDays,
          [day]: {
            confirmedBy,
            confirmedAt,
          },
        };
        setConfirmedDays(updatedConfirmedDays);

        // Save confirmed day with no lessons to Firestore
        const db = getFirestore();
        const classDocRef = doc(db, "students", selectedClass);

        await updateDoc(classDocRef, {
          confirmedDays: updatedConfirmedDays,
        });
      }
    } else {
      // Proceed with confirmation if there are lessons
      const subjectNames = dayLessons
        .map((lesson) => {
          const subject = subjects.find((sub) => sub.id === lesson.subjectId);
          return subject ? subject.name : "";
        })
        .filter((name) => name) // Remove any empty subject names
        .join(", ");

      if (
        window.confirm(
          `Confirm that the subjects for today were: ${subjectNames}. \n \nOnce confirmed, you will need to contact management to change attendance.`
        )
      ) {
        const confirmedBy = userData.name || userData.email || "Unknown User"; // Fallback to "Unknown User" if neither is available
        const confirmedAt = new Date(); // Store the actual Date object

        const updatedConfirmedDays = {
          ...confirmedDays,
          [day]: {
            confirmedBy,
            confirmedAt,
          },
        };
        setConfirmedDays(updatedConfirmedDays);

        // Save confirmed day with lessons to Firestore
        const db = getFirestore();
        const classDocRef = doc(db, "students", selectedClass);

        await updateDoc(classDocRef, {
          confirmedDays: updatedConfirmedDays,
        });
      }
    }
  };

  const handleUnconfirmDay = async (day) => {
    if (window.confirm("Are you sure you want to unconfirm this day?")) {
      const updatedConfirmedDays = { ...confirmedDays };
      delete updatedConfirmedDays[day]; // Remove the selected day

      setConfirmedDays(updatedConfirmedDays);

      // Update Firestore to remove the confirmed day
      const db = getFirestore();
      const classDocRef = doc(db, "students", selectedClass);
      await updateDoc(classDocRef, {
        confirmedDays: updatedConfirmedDays,
      });
    }
  };

  const formatDate = (date) => {
    return `${date.getDate()}/${date.getMonth() + 1}`;
  };

  const renderTableBody = () => {
    return students.map((student) => (
      <tr key={student.id}>
        <td>{student.name}</td>
        {daysOfWeek.map((day, index) => {
          const dayDate = new Date(currentWeek);
          dayDate.setDate(dayDate.getDate() - dayDate.getDay() + 1 + index);
          const formattedDate = dayDate.toISOString().slice(0, 10);
          const isDayConfirmed = !!confirmedDays[formattedDate]; // Check if the day is confirmed

          return (
            <td key={day}>
              {lessons[formattedDate]?.map((lesson) => {
                const { lessonId, subjectId } = lesson;
                const subject = subjects.find((sub) => sub.id === subjectId);
                const attendanceStatus =
                  attendance[student.id]?.[formattedDate]?.[lessonId] || "";

                return (
                  <div
                    key={lessonId}
                    className={`small-font lesson-item ${
                      isDayConfirmed ? "confirmed" : ""
                    }`}
                  >
                    <span
                      onClick={() =>
                        handleToggleDropdown(
                          student.id,
                          lessonId,
                          formattedDate
                        )
                      }
                      className={isDayConfirmed ? "confirmed-text" : ""}
                    >
                      {subject?.abbreviation}
                    </span>
                    {!showDropdowns[`${student.id}-${lessonId}`] && (
                      <span
                        onClick={() =>
                          handleToggleDropdown(
                            student.id,
                            lessonId,
                            formattedDate
                          )
                        }
                        className={isDayConfirmed ? "confirmed-text" : ""}
                      >
                        {getIcon(attendanceStatus)}
                      </span>
                    )}
                    {!isDayConfirmed &&
                      showDropdowns[`${student.id}-${lessonId}`] && (
                        <select
                          ref={dropdownRef}
                          value={attendanceStatus}
                          onChange={(e) =>
                            handleAttendanceChange(
                              student.id,
                              formattedDate,
                              lessonId,
                              e.target.value
                            )
                          }
                        >
                          <option value="" disabled>
                            Select status
                          </option>
                          {attendanceOptions.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                      )}
                  </div>
                );
              })}
            </td>
          );
        })}
      </tr>
    ));
  };

  const handleDeleteLesson = (lessonId, day) => {
    // Find the lesson based on lessonId
    const lessonToDelete = lessons[day].find(
      (lesson) => lesson.lessonId === lessonId
    );

    if (!lessonToDelete) {
      alert("Lesson not found!");
      return;
    }

    // Find the subject name for the lesson
    const subject = subjects.find((sub) => sub.id === lessonToDelete.subjectId);
    const subjectName = subject ? subject.name : "Unknown Subject";

    const isConfirmed = window.confirm(
      `Are you sure you want to delete the lesson ${subjectName} (${lessonToDelete.startTime} - ${lessonToDelete.endTime}) and all associated attendance?`
    );

    if (!isConfirmed) return;

    // Update lessons state immediately
    setLessons((prevLessons) => {
      const updatedLessons = { ...prevLessons };
      updatedLessons[day] = updatedLessons[day].filter(
        (lesson) => lesson.lessonId !== lessonId
      );
      return updatedLessons;
    });

    // Update attendance state immediately
    setAttendance((prevAttendance) => {
      const updatedAttendance = { ...prevAttendance };
      students.forEach((student) => {
        if (updatedAttendance[student.id]?.[day]) {
          delete updatedAttendance[student.id][day][lessonId];
        }
      });
      return updatedAttendance;
    });

    // Update Firestore in the background after state update
    const updateFirestore = async () => {
      const db = getFirestore();
      const classDocRef = doc(db, "students", selectedClass);

      const updatedStudents = students.map((student) => ({
        ...student,
        attendance: {
          ...student.attendance,
          ...attendance[student.id],
        },
      }));

      const updatedLessons = { ...lessons };
      updatedLessons[day] = updatedLessons[day].filter(
        (lesson) => lesson.lessonId !== lessonId
      );

      await updateDoc(classDocRef, {
        lessons: updatedLessons,
        students_list: updatedStudents,
      });
    };

    updateFirestore(); // Firestore is updated after the state is updated
  };

  const renderTodayLessons = () => {
    return (
      <tr className="lightbluebg">
        <td>Today's Lessons</td>
        {daysOfWeek.map((day, index) => {
          const dayDate = new Date(currentWeek);
          dayDate.setDate(dayDate.getDate() - dayDate.getDay() + 1 + index);
          const formattedDate = dayDate.toISOString().slice(0, 10);

          const sortedLessons = (lessons[formattedDate] || []).sort((a, b) => {
            const timeA = a.startTime.replace(":", "");
            const timeB = b.startTime.replace(":", "");
            return timeA.localeCompare(timeB); // Sort by startTime
          });

          const isDayConfirmed = !!confirmedDays[formattedDate];

          return (
            <td key={day}>
              {sortedLessons.map((lesson) => {
                const { startTime, endTime, subjectId, lessonId } = lesson;
                const subject = subjects.find((sub) => sub.id === subjectId);

                return (
                  <div key={lessonId} className="todays-lessons">
                    {!isDayConfirmed && editingLessonId === lessonId ? (
                      <div>
                        <input
                          type="time"
                          className="student-attendance-time-input"
                          value={startTime}
                          onChange={(e) =>
                            handleLessonTimeChange(
                              lessonId,
                              "startTime",
                              e.target.value
                            )
                          }
                        />
                        <input
                          type="time"
                          className="student-attendance-time-input"
                          value={endTime}
                          onChange={(e) =>
                            handleLessonTimeChange(
                              lessonId,
                              "endTime",
                              e.target.value
                            )
                          }
                        />
                        <select
                          value={subjectId}
                          onChange={(e) =>
                            handleLessonSubjectChange(lessonId, e.target.value)
                          }
                        >
                          {subjects.map((subject) => (
                            <option key={subject.id} value={subject.id}>
                              {subject.abbreviation}
                            </option>
                          ))}
                        </select>
                        <button
                          onClick={() => {
                            setEditingLessonId(null);
                            saveUpdatedLessons(); // Save changes to Firestore
                          }}
                        >
                          Save
                        </button>
                      </div>
                    ) : (
                      <div>
                        {isAdmin && !isDayConfirmed && (
                          <span
                            className="delete-lesson-icon"
                            onClick={() =>
                              handleDeleteLesson(lessonId, formattedDate)
                            }
                          >
                            <i class="fa-regular fa-square-minus"></i>
                          </span>
                        )}{" "}
                        <span
                          onClick={() => setEditingLessonId(lessonId)}
                          className={isDayConfirmed ? "confirmed-text" : ""}
                        >
                          {startTime} - {endTime} {subject?.abbreviation}
                        </span>
                      </div>
                    )}
                  </div>
                );
              })}
            </td>
          );
        })}
      </tr>
    );
  };

  const renderView = () => {
    const today = new Date();

    return (
      <div>
        {" "}
        <div className="class-selector">
          <label>Select Class: </label>
          <select
            value={selectedClass}
            onChange={(e) => {
              setSelectedClass(e.target.value);
              localStorage.setItem("selectedClass", e.target.value);
              fetchStudentsForClass(e.target.value);
            }}
          >
            <option value="">Select Class</option>
            {studentClasses.map((studentClass) => (
              <option key={studentClass.id} value={studentClass.id}>
                {studentClass.name}
              </option>
            ))}
          </select>
        </div>
        <br />
        {selectedClass && (
          <div>
            <h3>Create Lesson</h3>
            <div className="create-lesson-parent">
              Day:{" "}
              <select
                value={newLesson.day}
                onChange={(e) =>
                  setNewLesson({ ...newLesson, day: e.target.value })
                }
              >
                <option value="" disabled>
                  Select Day
                </option>
                {daysOfWeek.map((day, index) => {
                  const dayDate = new Date(currentWeek);
                  dayDate.setDate(
                    dayDate.getDate() - dayDate.getDay() + 1 + index
                  );
                  const formattedDate = dayDate.toISOString().slice(0, 10);

                  // Format the date to display as "dd/mm"
                  const displayDate = `${dayDate.getDate()}/${
                    dayDate.getMonth() + 1
                  }`;

                  // Check if the day is confirmed
                  const isConfirmed = !!confirmedDays[formattedDate];

                  return (
                    <option
                      key={day}
                      value={formattedDate}
                      disabled={isConfirmed}
                    >
                      {day} {displayDate} {isConfirmed ? "(confirmed)" : ""}
                    </option>
                  );
                })}
              </select>
              Subject:
              <select
                value={newLesson.subjectId}
                onChange={(e) =>
                  setNewLesson({ ...newLesson, subjectId: e.target.value })
                }
              >
                <option value="" disabled>
                  Select Subject
                </option>
                {subjects.map((subject) => (
                  <option key={subject.id} value={subject.id}>
                    {subject.name}
                  </option>
                ))}
              </select>
              From:
              <input
                type="time"
                className="student-attendance-time-input"
                value={newLesson.startTime}
                onChange={(e) =>
                  setNewLesson({ ...newLesson, startTime: e.target.value })
                }
              />
              To:
              <input
                type="time"
                className="student-attendance-time-input"
                value={newLesson.endTime}
                onChange={(e) =>
                  setNewLesson({ ...newLesson, endTime: e.target.value })
                }
              />
              <label>
                <input
                  type="checkbox"
                  checked={markAllPresent}
                  onChange={() => setMarkAllPresent(!markAllPresent)}
                />
                Mark all students as present
              </label>
              <button onClick={handleLessonCreation}>Add Lesson</button>
            </div>
          </div>
        )}
        <br />
        {selectedClass && (
          <div className="week-navigation">
            <button onClick={handlePreviousWeek}>Previous Week</button>{" "}
            <span>
              {formatDate(getStartOfWeek())} - {formatDate(getEndOfWeek())}
            </span>{" "}
            <button onClick={handleNextWeek} disabled={isNextWeekDisabled()}>
              Next Week
            </button>
            <ReactDatePicker
              selected={currentWeek}
              onChange={handleWeekChange}
              customInput={<CalendarIconInput />} // Use the custom icon input
              showWeekNumbers
              dateFormat="yyyy-MM-dd"
              calendarStartDay={1} // Set Monday as the start day of the week
              maxDate={today}
            />
          </div>
        )}
        {selectedClass && students.length > 0 && (
          <div className="student-attendance-table-parent">
            {selectedClass && students.length > 0 && (
              <div className="student-attendance-table">
                <h3>Attendance for Week {getWeekNumber(getStartOfWeek())}</h3>
                <table className="student-attendance-table">
                  <thead>
                    <tr className="lightbluebg">
                      <th>Week {getWeekNumber(getStartOfWeek())}</th>
                      {daysOfWeek.map((day, index) => {
                        const dayDate = new Date(currentWeek);
                        dayDate.setDate(
                          dayDate.getDate() - dayDate.getDay() + 1 + index
                        );
                        const formattedDate = `${day} ${dayDate.getDate()}/${
                          dayDate.getMonth() + 1
                        }`;
                        return <th key={day}>{formattedDate}</th>;
                      })}
                    </tr>
                    <tr>
                      <th>
                        <span className="small-font">Confirmed by</span>
                      </th>
                      {daysOfWeek.map((day, index) => {
                        const dayDate = new Date(currentWeek);
                        dayDate.setDate(
                          dayDate.getDate() - dayDate.getDay() + 1 + index
                        );
                        const formattedDate = dayDate
                          .toISOString()
                          .slice(0, 10);

                        const confirmationInfo = confirmedDays[formattedDate];

                        return (
                          <th key={day}>
                            {!confirmationInfo ? (
                              <button
                                onClick={() => handleConfirmDay(formattedDate)}
                                className="confirm-button"
                                disabled={!!confirmationInfo} // Disable if already confirmed
                              >
                                Confirm
                              </button>
                            ) : (
                              <div className="small-font">
                                <span>{confirmationInfo.confirmedBy}</span>
                                <br />
                                <span>
                                  {confirmationInfo.confirmedAt?.toDate
                                    ? confirmationInfo.confirmedAt
                                        .toDate()
                                        .toLocaleString()
                                    : new Date(
                                        confirmationInfo.confirmedAt
                                      ).toLocaleString()}
                                </span>
                                {isAdmin && (
                                  <>
                                    <br />
                                    <br />
                                    <span
                                      onClick={() =>
                                        handleUnconfirmDay(formattedDate)
                                      }
                                      className="unconfirm-text"
                                    >
                                      <i class="fa-solid fa-arrow-rotate-left"></i>{" "}
                                      Unconfirm
                                    </span>
                                  </>
                                )}
                              </div>
                            )}
                          </th>
                        );
                      })}
                    </tr>
                  </thead>

                  <tbody>
                    {renderTodayLessons()}
                    {renderTableBody()}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      <div>
        <Locationscroll />
        <div className="attendance-page">
          <h2>Student Attendance</h2>
          {loading ? <div>Loading...</div> : renderView()}
        </div>
      </div>
    </>
  );
};

export default StudentAttendance;
