import React, { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { Navbar, Footer } from "../../../components";
import Locationscroll from "../../../components/Locationscroll/Locationscroll";
import { useAuth } from "../../../AuthProvider";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../../../firebase";
import Reports from "./Reports";
import StudentGrades from "./StudentGrades";
import AdminSettings from "./Admin/AdminSettings";
import StudentAttendance from "./StudentAttendance";
import Overview from "./Overview";
import Students from "./Students";
import Economics from "./Economics";
import Sponsorships from "./Sponsorships/Sponsorships";
import Email from "./Admin/Email";
import Newsletters from "./Newsletters/Newsletters";
import "./Intranet.css";

const Intranet = () => {
  const { isAdmin, userData } = useAuth();
  const [activePage, setActivePage] = useState("overview");
  const [intranetAccess, setIntranetAccess] = useState([]);
  const [pendingRequests, setPendingRequests] = useState(0);
  const [awaitingReceiptCount, setAwaitingReceiptCount] = useState(0);
  const [adminReportsToReview, setAdminReportsToReview] = useState(0);
  const [userReportsToHandle, setUserReportsToHandle] = useState({
    toCreate: 0,
    toConfirm: 0,
  });

  const pages = [
    { key: "overview", label: "Overview", icon: "fa-house" },
    { key: "reports", label: "Reports", icon: "fa-folder-open" },
    { key: "economics", label: "Economics", icon: "fa-coins" },
    { key: "students", label: "Students", icon: "fa-child" },
    {
      key: "studentattendance",
      label: "Attendance",
      icon: "fa-clipboard-user",
    },
    { key: "studentgrades", label: "Grades", icon: "fa-graduation-cap" },
    {
      key: "sponsorships",
      label: "Sponsorships",
      icon: "fa-hand-holding-heart",
    },
    { key: "newsletters", label: "Newsletters", icon: "fa-envelope-open-text" },
    { key: "email", label: "Email", icon: "fa-envelope-open-text" },
    { key: "adminsettings", label: "Admin", icon: "fa-screwdriver-wrench" },
  ];

  useEffect(() => {
    const fetchUserAccess = async () => {
      if (!userData || !userData.email) return;
      const staffCollection = collection(db, "staff");
      const staffQuery = query(
        staffCollection,
        where("email", "==", userData.email)
      );
      const staffSnapshot = await getDocs(staffQuery);
      if (staffSnapshot.docs.length > 0) {
        const staffData = staffSnapshot.docs[0].data();
        setIntranetAccess(staffData.intranet_access || []);
      }
    };

    fetchUserAccess();
  }, [userData, isAdmin]);

  useEffect(() => {
    const fetchAllCounts = async () => {
      try {
        let economicsQuery, reportsQuery;

        // Fetch economic data for user/admin
        if (isAdmin) {
          economicsQuery = collection(db, "economics");
          reportsQuery = collection(db, "reports");
        } else {
          economicsQuery = query(
            collection(db, "economics"),
            where("userId", "==", userData.email)
          );
          reportsQuery = query(
            collection(db, "reports"),
            where("userId", "==", userData.email)
          );
        }

        const economicsSnapshot = await getDocs(economicsQuery);
        const reportsSnapshot = await getDocs(reportsQuery);

        const economicsData = economicsSnapshot.docs.map((doc) => doc.data());
        const reportsData = reportsSnapshot.docs.map((doc) => doc.data());

        // Set economics-related counts
        if (isAdmin) {
          const pending = economicsData.filter(
            (request) => request.status === "Awaiting approval"
          ).length;
          setPendingRequests(pending);
        } else {
          const awaitingReceipts = economicsData.filter(
            (request) =>
              request.status === "Approved" ||
              request.status === "Partially approved"
          ).length;
          setAwaitingReceiptCount(awaitingReceipts);
        }

        // Set report-related counts
        if (isAdmin) {
          const toReview = reportsData.filter(
            (report) => report.status === "submitted"
          ).length;
          setAdminReportsToReview(toReview);
        } else {
          const toCreate = reportsData.filter(
            (report) => report.week === `Week ${getWeekNumber(new Date())}`
          ).length
            ? 0
            : 1;
          const toConfirm = reportsData.filter(
            (report) => report.status === "awaitingRead"
          ).length;

          setUserReportsToHandle({ toCreate, toConfirm });
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchAllCounts(); // Fetch data when the component mounts
  }, [isAdmin, userData]);

  const getWeekNumber = (date) => {
    const startDate = new Date(date.getFullYear(), 0, 1); // January 1st
    const startDayOfWeek = startDate.getDay(); // Day of the week for January 1st
    const dayOfYear =
      Math.floor((date - startDate) / (24 * 60 * 60 * 1000)) + 1; // Day of the year

    // Calculate the week number, adjusting for the first partial week
    const weekNumber = Math.ceil((dayOfYear + (startDayOfWeek - 1)) / 7);
    return weekNumber;
  };

  const updateActivePage = (page) => {
    setActivePage(page);
    window.location.hash = page;
    localStorage.setItem("lastPage", page);
  };

  useEffect(() => {
    const handleHashChange = () => {
      const hash = window.location.hash.substring(1);
      setActivePage(hash || "overview");
    };

    const lastPage = localStorage.getItem("lastPage");
    if (lastPage) {
      setActivePage(lastPage);
      window.location.hash = lastPage;
    } else {
      handleHashChange();
    }

    window.addEventListener("hashchange", handleHashChange);
    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, []);

  const renderPage = () => {
    switch (activePage) {
      case "overview":
        return <Overview />;
      case "reports":
        return (
          <Reports
            onAdminReportsToReview={setAdminReportsToReview}
            onUserReportsToHandle={setUserReportsToHandle}
          />
        );
      case "studentattendance":
        return <StudentAttendance />;
      case "studentgrades":
        return <StudentGrades />;
      case "students":
        return <Students />;
      case "economics":
        return (
          <Economics
            onPendingRequestCount={setPendingRequests}
            onAwaitingReceiptCount={setAwaitingReceiptCount}
          />
        );
      case "sponsorships":
        return <Sponsorships />;
      case "newsletters":
        return <Newsletters />;
      case "email":
        return <Email />;
      case "adminsettings":
        return isAdmin ? <AdminSettings /> : <Overview />;
      default:
        return <Overview />;
    }
  };

  return (
    <>
      <div>
        <Locationscroll />
        <Navbar isAdmin={isAdmin} />
        <div className="row2" id="flex">
          <div className="main">
            <div className="intranet-navigation">
              {pages
                .filter(
                  (page) =>
                    intranetAccess.includes(page.key) ||
                    (isAdmin && page.key === "adminsettings")
                )
                .map((page) => (
                  <button
                    key={page.key}
                    className={`nav-button ${
                      activePage === page.key ? "active" : ""
                    }`}
                    onClick={() => updateActivePage(page.key)}
                  >
                    <i className={`fa-solid ${page.icon}`}></i> {page.label}
                    {isAdmin &&
                      page.key === "reports" &&
                      adminReportsToReview > 0 && (
                        <span className="request-count">
                          {adminReportsToReview}
                        </span>
                      )}
                    {!isAdmin &&
                      page.key === "reports" &&
                      (userReportsToHandle.toCreate > 0 ||
                        userReportsToHandle.toConfirm > 0) && (
                        <span className="request-count">
                          {userReportsToHandle.toCreate +
                            userReportsToHandle.toConfirm}
                        </span>
                      )}
                    {isAdmin &&
                      page.key === "economics" &&
                      pendingRequests > 0 && (
                        <span className="request-count">{pendingRequests}</span>
                      )}
                    {!isAdmin &&
                      page.key === "economics" &&
                      awaitingReceiptCount > 0 && (
                        <span className="request-count">
                          {awaitingReceiptCount}
                        </span>
                      )}
                  </button>
                ))}
            </div>
            <div className="intranet-content">{renderPage()}</div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Intranet;
