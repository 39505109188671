import React, { createContext, useState, useEffect } from "react";
import { collection, query, where, onSnapshot } from "firebase/firestore";
import { db } from "../../../firebase";
import { useAuth } from "../../../AuthProvider";

export const EconomicsContext = createContext();

export const EconomicsProvider = ({ children }) => {
  const { userData, isAdmin } = useAuth();
  const [requests, setRequests] = useState([]); // Initialize to empty array

  useEffect(() => {
    if (!userData) {
      setRequests([]); // Ensure requests is an empty array when userData is not available
      return;
    }

    let requestQuery;
    if (isAdmin) {
      requestQuery = collection(db, "economics");
    } else {
      requestQuery = query(
        collection(db, "economics"),
        where("userId", "==", userData.email)
      );
    }

    const unsubscribe = onSnapshot(requestQuery, (snapshot) => {
      const fetchedRequests = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setRequests(fetchedRequests);
    });

    return () => unsubscribe();
  }, [userData, isAdmin]);

  return (
    <EconomicsContext.Provider value={{ requests, setRequests }}>
      {children}
    </EconomicsContext.Provider>
  );
};