import React, { useState, useEffect } from "react";
import {
  getFirestore,
  doc,
  getDoc,
  getDocs,
  collection,
  setDoc,
} from "firebase/firestore";

const IncomeExpenses = () => {
  const [totalMonthlyIncomeKES, setTotalMonthlyIncomeKES] = useState(0);
  const [totalExpensesKES, setTotalExpensesKES] = useState(0);
  const [inputExpensesKES, setInputExpensesKES] = useState(0);
  const [percentageReached, setPercentageReached] = useState(0);

  // Hardcoded exchange rates
  const exchangeRates = {
    KES: 1,
    USD: 129.8701,
    NOK: 12.19512,
  };

  const db = getFirestore();
  const adminDocRef = doc(db, "admin", "income-expenses");

  useEffect(() => {
    const fetchSponsorships = async () => {
      try {
        const sponsorshipsSnapshot = await getDocs(
          collection(db, "sponsorships")
        );
        const sponsorshipsData = sponsorshipsSnapshot.docs.map((doc) =>
          doc.data()
        );

        // Filter only "Active" sponsorships that are included in the monthly income calculation
        const activeSponsorships = sponsorshipsData.filter(
          (s) => s.status === "Active" && s.includeInMonthlyIncome === true
        );

        let totalIncome = 0;

        activeSponsorships.forEach((sponsorship) => {
          const { commitmentAmount, commitmentPeriod, currency } = sponsorship;

          // Validate necessary fields
          if (!commitmentAmount || !commitmentPeriod || !currency) return;

          const amount = parseFloat(commitmentAmount);
          const period = parseFloat(commitmentPeriod);
          const rate = exchangeRates[currency];

          // Skip if data is invalid or currency rate is not defined
          if (isNaN(amount) || isNaN(period) || period === 0 || !rate) return;

          // Calculate monthly amount in KES
          const monthlyAmountKES = (amount / period) * rate;
          totalIncome += monthlyAmountKES;
        });

        setTotalMonthlyIncomeKES(totalIncome);
      } catch (error) {
        console.error("Error fetching sponsorships:", error);
      }
    };

    const fetchExpenses = async () => {
      try {
        const docSnap = await getDoc(adminDocRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          setTotalExpensesKES(data.totalExpenses || 0); // Load expenses from Firestore
          setInputExpensesKES(data.totalExpenses || 0); // Initialize input with the same value
        }
      } catch (error) {
        console.error("Error fetching expenses:", error);
      }
    };

    fetchSponsorships();
    fetchExpenses();
  }, [db]); // Ensure this runs once when the component mounts

  useEffect(() => {
    // Calculate percentage reached when income or expenses change
    if (totalExpensesKES > 0) {
      const percentage = (totalMonthlyIncomeKES / totalExpensesKES) * 100;
      setPercentageReached(percentage);
    } else {
      setPercentageReached(0); // Avoid division by zero
    }
  }, [totalMonthlyIncomeKES, totalExpensesKES]); // Recalculate when income or expenses change

  const handleUpdateExpenses = async () => {
    const newExpenses = parseFloat(inputExpensesKES) || 0;
    setTotalExpensesKES(newExpenses);

    try {
      // Update Firestore with new total expenses
      await setDoc(
        adminDocRef,
        { totalExpenses: newExpenses },
        { merge: true }
      );

      alert("Expenses updated successfully!");
    } catch (error) {
      console.error("Error updating expenses:", error);
    }
  };

  return (
    <div>
      <p>
        Total Monthly Income: KES {totalMonthlyIncomeKES.toFixed(2)} | Total
        Expenses: KES{" "}
        <input
          type="number"
          value={inputExpensesKES}
          onChange={(e) => setInputExpensesKES(e.target.value)}
        />
        <button onClick={handleUpdateExpenses}>Update</button> | Percentage
        Reached: <b>{percentageReached.toFixed(2)}%</b>
      </p>
    </div>
  );
};

export default IncomeExpenses;
