import React, { useState, useEffect, useRef } from "react";
import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
  addDoc,
  setDoc,
  getDoc,
  doc,
} from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { db } from "../../firebase";
import { useAuth } from "../../AuthProvider";
import { Navbar, Footer, SponsorSide, MyLink } from "../../components";
import { images } from "../../constants";
import Locationscroll from "../../components/Locationscroll/Locationscroll";
import { useNavigate, useParams } from "react-router-dom";
import SponsorForm from "../../components/SponsorForm/SponsorForm";
import "./Intranet.css";

const Sponsorship = () => {
  const { isAdmin, isSponsor, userData } = useAuth();
  const [selectedClassName, setSelectedClassName] = useState("Grade 7 - 2024");
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [studentsList, setStudentsList] = useState([]);
  const [newStudentName, setNewStudentName] = useState("");
  const [newStudentBirthday, setNewStudentBirthday] = useState("");
  const [newStudentImage, setNewStudentImage] = useState(null);
  const [newStudentImage2, setNewStudentImage2] = useState(null);
  const [newStudentGender, setNewStudentGender] = useState();
  const [newStudentDescription, setNewStudentDescription] = useState("");
  const [newStudentMessage, setNewStudentMessage] = useState("");
  const [loadingStudents, setLoadingStudents] = useState(true);
  const [students, setStudents] = useState([]);
  const [edit, setEdit] = useState(false);
  const navigate = useNavigate();
  const { student } = useParams();
  const [activeOption, setActiveOption] = useState("norwegian");
  const [sponsorStatus, setSponsorStatus] = useState(false);
  const [sponsoredStudents, setSponsoredStudents] = useState(new Set());

  useEffect(() => {
    const fetchStudents = async () => {
      try {
        const studentCollection = collection(db, "students");
        const studentSnapshot = await getDocs(studentCollection);
        const studentData = studentSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setStudents(studentData);
      } catch (error) {
        console.error("Error fetching students:", error);
      }
    };

    fetchStudents();
  }, []);

  const handleClassSelect = (event) => {
    setSelectedClassName(event.target.value);
  };

  useEffect(() => {
    const fetchStudents = async () => {
      if (!selectedClassName) return;
      setLoadingStudents(true);
      console.log("Fetching students for class:", selectedClassName);
      const db = getFirestore();
      const studentsCollection = collection(db, "students");
      const q = query(
        studentsCollection,
        where("name", "==", selectedClassName)
      );
      const querySnapshot = await getDocs(q);
      const studentData = [];
      querySnapshot.forEach((doc) => {
        const classData = doc.data();
        const students = classData.students_list || [];
        students.forEach((student) => {
          studentData.push({ id: doc.id, ...student });
        });
      });
      console.log("Fetched students:", studentData);
      setStudentsList(studentData);
      setLoadingStudents(false);
    };

    fetchStudents();
  }, [selectedClassName]);

  useEffect(() => {
    if (studentsList.length > 0 && student) {
      const foundStudent = studentsList.find((s) => s.id === student);
      if (foundStudent) {
        setSelectedStudent(foundStudent);
        setSponsorStatus(foundStudent.sponsor === "yes");
      }
    }
  }, [studentsList, student]);

  useEffect(() => {
    if (selectedStudent) {
      setNewStudentName(selectedStudent.name);
      setNewStudentBirthday(
        selectedStudent.birthday.toDate().toISOString().split("T")[0]
      );
      setNewStudentGender(selectedStudent.gender);
      setNewStudentDescription(selectedStudent.description);
      setNewStudentMessage(selectedStudent.message);
    }
  }, [selectedStudent]);

  useEffect(() => {
    const fetchSponsoredStudents = async () => {
      try {
        const sponsorshipsCollection = collection(db, "sponsorships");
        const sponsorshipsSnapshot = await getDocs(sponsorshipsCollection);
        const sponsoredNamesSet = new Set();
        sponsorshipsSnapshot.forEach((doc) => {
          const data = doc.data();
          if (data.studentName) {
            sponsoredNamesSet.add(data.studentName);
          }
        });
        setSponsoredStudents(sponsoredNamesSet);
      } catch (error) {
        console.error("Error fetching sponsorships:", error);
      }
    };

    fetchSponsoredStudents();
  }, []);

  useEffect(() => {
    const fetchSponsorshipStatus = async (studentName) => {
      try {
        const sponsorshipsCollection = collection(db, "sponsorships");
        const q = query(
          sponsorshipsCollection,
          where("studentName", "==", studentName)
        );
        const querySnapshot = await getDocs(q);
        const isSponsored = !querySnapshot.empty;
        setSponsorStatus(isSponsored);

        if (!isAdmin && isSponsored) {
          // Redirect non-admin users away from sponsored students
          console.log("Access restricted to sponsored students.");
          navigate("/sponsorship");
        } else {
          setSelectedStudent(foundStudent);
        }
      } catch (error) {
        console.error("Error checking sponsorship status:", error);
      }
    };

    if (studentsList.length > 0 && student) {
      const foundStudent = studentsList.find((s) => s.id === student);
      if (foundStudent) {
        fetchSponsorshipStatus(foundStudent.name);
      }
    }
  }, [studentsList, student, sponsoredStudents, isAdmin]);

  const filteredStudentsList = isAdmin
    ? studentsList
    : studentsList.filter((student) => !sponsoredStudents.has(student.name));

  const handleReadMore = (student) => {
    const isSponsored = sponsoredStudents.has(student.name);
    if (isSponsored && isAdmin) {
      navigate(`/admin/sponsorship/${student.id}`);
    } else if (!isSponsored) {
      navigate(`/sponsorship/${student.id}`);
    } else {
      console.log("Access restricted to admins only.");
      // Optionally, display a message to the user
    }
  };

  const handleGoBack = () => {
    setSelectedStudent(null);
    isAdmin ? navigate("/admin/sponsorship") : navigate("/sponsorship");
  };

  const handleNextStudent = () => {
    if (filteredStudentsList.length === 0) return; // No students to navigate

    if (!selectedStudent) {
      setSelectedStudent(filteredStudentsList[0]);
      navigateToStudent(filteredStudentsList[0]);
    } else {
      const currentIndex = filteredStudentsList.findIndex(
        (student) => student.id === selectedStudent.id
      );

      if (currentIndex !== -1) {
        const nextIndex = (currentIndex + 1) % filteredStudentsList.length;
        const nextStudent = filteredStudentsList[nextIndex];
        setSelectedStudent(nextStudent);
        navigateToStudent(nextStudent);
      }
    }
  };

  const handlePreviousStudent = () => {
    if (filteredStudentsList.length === 0) return; // No students to navigate

    if (!selectedStudent) {
      setSelectedStudent(filteredStudentsList[0]);
      navigateToStudent(filteredStudentsList[0]);
    } else {
      const currentIndex = filteredStudentsList.findIndex(
        (student) => student.id === selectedStudent.id
      );

      if (currentIndex !== -1) {
        const previousIndex =
          (currentIndex - 1 + filteredStudentsList.length) %
          filteredStudentsList.length;
        const previousStudent = filteredStudentsList[previousIndex];
        setSelectedStudent(previousStudent);
        navigateToStudent(previousStudent);
      }
    }
  };

  const navigateToStudent = (student) => {
    if (isAdmin) {
      navigate(`/admin/sponsorship/${student.id}`);
    } else {
      navigate(`/sponsorship/${student.id}`);
    }
  };

  const handleStudentUpdate = async () => {
    const storage = getStorage();
    let imageUrl = selectedStudent.image;
    if (newStudentImage) {
      const storageRef = ref(storage, `student_images/${newStudentImage.name}`);
      await uploadBytes(storageRef, newStudentImage);
      imageUrl = await getDownloadURL(storageRef);
    }
    let imageUrl2 = selectedStudent.image2;
    if (newStudentImage2) {
      const storageRef2 = ref(
        storage,
        `student_images/${newStudentImage2.name}`
      );
      await uploadBytes(storageRef2, newStudentImage2);
      imageUrl2 = await getDownloadURL(storageRef2);
    }

    const updatedStudent = {
      name: newStudentName,
      image: imageUrl,
      birthday: new Date(newStudentBirthday),
      gender: newStudentGender,
      description: newStudentDescription,
      image2: imageUrl2,
      message: newStudentMessage,
    };

    // Update Firestore with the updated student data
    const db = getFirestore();
    const studentsCollectionRef = collection(db, "students");
    const classId = students.find(
      (classObj) => classObj.name === selectedClassName
    ).id;
    const groupDocRef = doc(studentsCollectionRef, classId);

    const groupDocSnapshot = await getDoc(groupDocRef);
    if (groupDocSnapshot.exists()) {
      const groupData = groupDocSnapshot.data();
      const studentsList = groupData.students_list || [];
      const updatedStudentsList = studentsList.map((student) => {
        if (student.id === selectedStudent.id) {
          return { ...student, ...updatedStudent };
        }
        return student;
      });
      await setDoc(
        groupDocRef,
        { students_list: updatedStudentsList },
        { merge: true }
      );
    }

    console.log("Student updated successfully");
    alert("Student updated successfully. Refresh to see changes.");
    setEdit(false);
  };

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    setNewStudentImage(file);
  };

  const handleImageUpload2 = async (e) => {
    const file = e.target.files[0];
    setNewStudentImage2(file);
  };

  const calculateAge = (birthday) => {
    const today = new Date();
    const birthDate = new Date(birthday.toDate());
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }
    return age;
  };

  const firstName =
    selectedStudent && selectedStudent.name
      ? selectedStudent.name.split(" ")[0]
      : "";
  const pronoun =
    selectedStudent && selectedStudent.gender === "Boy" ? "his" : "her";
  const pronoun_2 =
    selectedStudent && selectedStudent.gender === "Boy" ? "him" : "her";
  const gender =
    selectedStudent && selectedStudent.gender === "Boy" ? "he" : "she";

  const paragraphs =
    selectedStudent && selectedStudent.description
      ? selectedStudent.description.split("\n")
      : [];

  // Extract the first paragraph and remaining paragraphs
  const firstParagraph = paragraphs.length > 0 ? paragraphs[0] : "";
  const remainingParagraphs = paragraphs.slice(1);

  studentsList.sort((a, b) => {
    const aSponsored = sponsoredStudents.has(a.name);
    const bSponsored = sponsoredStudents.has(b.name);
    if (aSponsored && !bSponsored) return 1;
    if (!aSponsored && bSponsored) return -1;
    return 0;
  });

  const [isInfoVisible, setIsInfoVisible] = useState(false);
  const [isRandomChildVisible, setIsRandomChildVisible] = useState(false);

  const toggleVisibility = () => {
    setIsInfoVisible(!isInfoVisible);
    setIsRandomChildVisible(false);
  };

  const toggleRandomChild = () => {
    setIsRandomChildVisible(!isRandomChildVisible);
    setIsInfoVisible(false);
  };

  return (
    <>
      <div>
        <Locationscroll />
        <Navbar isAdmin={isAdmin} />
        <div className="row" id="flex">
          <SponsorSide />
          <div className="main">
            <div className="article">
              <div>
                <h2>Sponsorship</h2>
                <p>
                  For as little as US $1 per day, or $30 a month, sponsor a
                  child in the Kibera slums - offering life and hope, quality
                  education, medical assistance and better living standards for
                  them and their families.
                </p>

                <div
                  className="feature-box blue sponsorshipInfo"
                  onClick={toggleVisibility}
                >
                  <i class="fa-regular fa-circle-question"></i> How does a
                  sponsorship work?
                </div>

                <div
                  className="feature-box green sponsorshipInfo"
                  onClick={toggleRandomChild}
                >
                  <i class="fa-solid fa-child"></i> Sponsor a random child
                </div>

                {isInfoVisible && (
                  <div className="hideable-content" onClick={toggleVisibility}>
                    <h3>What does it mean to sponsor a child with us?</h3>

                    <p>
                      We are committed to ensuring that our programs are not
                      only meaningful, impactful, and long-term but also
                      accessible to all.
                    </p>
                    <p>
                      - Just $30 or 300 Norwegian kroner per month can transform
                      a child’s life forever. Your contribution will not only
                      support their education but also cover their nutritional,
                      medical, and basic material needs.
                    </p>
                    <p>
                      - The children who are approved to receive support have
                      lost one or both parents and come from families so poor
                      that they would not have the opportunity to escape
                      poverty.
                    </p>

                    <h3>
                      What is unique from just giving to the organization
                      directly?
                    </h3>
                    <p>
                      - Through letters and updates, you will be able to build a
                      relationship with your sponsored child and see the
                      difference you make in your child's life. You can engage
                      as much or little as you want with your child. You will
                      receive three letters a year from your sponsored child, as
                      well as a biannual newsletter with updates on our
                      education program.
                    </p>
                    <p>
                      A portion of the monthly sum goes to cover the child's
                      most essential needs, such as a mattress to sleep on, or
                      an iron sheet if the roof has holes.
                    </p>

                    <h3>What do I have to do?</h3>
                    <p>
                      - As a sponsor, you commit to paying a fixed monthly
                      amount of $30 or 300 Norwegian kroner per child. This
                      amount can be paid monthly, quarterly, semi-annually, or
                      annually. You can pay using the payment option below,
                      through online banking, or by setting up a direct debit
                      with your bank. You are a sponsor for as long as you wish,
                      and you can terminate your commitment at any time.
                    </p>
                    <h3>How long does the sponsorship last?</h3>
                    <p>
                      - We have set the limit for the duration of sponsorship
                      until they are 18 years old. When the child completes
                      lower secondary school, it is possible to help the child
                      continue to upper secondary school and further to higher
                      education.
                    </p>
                    <div
                      className="feature-box blue closeInfo"
                      onClick={toggleVisibility}
                    >
                      Close
                    </div>
                  </div>
                )}

                {isRandomChildVisible && (
                  <div>
                    <br />
                    <div className="article sponsoroptions">
                      <div
                        className={`option ${
                          activeOption === "norwegian" ? "active" : ""
                        }`}
                        onClick={() => setActiveOption("norwegian")}
                      >
                        <p>🇳🇴 Norwegian</p>
                      </div>

                      <div
                        className={`option ${
                          activeOption === "other" ? "active" : ""
                        }`}
                        onClick={() => setActiveOption("other")}
                      >
                        {" "}
                        <p>🌍 International</p>
                      </div>
                    </div>
                    <br />
                    {activeOption === "norwegian" ? (
                      <>
                        <SponsorForm random="true" />
                      </>
                    ) : (
                      <>
                        <h3>To sponsor a child costs $30 per month.</h3>
                        <p>
                          To sponsor a specific child, add the child's name as a
                          comment below.
                        </p>
                        <iframe
                          src="https://donorbox.org/embed/donate-to-the-watoto-library?default_interval=m&amount=30"
                          name="donorbox"
                          allowpaymentrequest="allowpaymentrequest"
                          seamless="seamless"
                          frameborder="0"
                          scrolling="no"
                          height="900px"
                          width="100%"
                          style={{
                            maxWidth: "500px",
                            minWidth: "250px",
                            maxHeight: "none!important",
                            minHeight: "650px",
                          }}
                          allow="payment"
                        ></iframe>
                        <br />

                        {/* <PayPalButton key={activeOption} /> */}
                      </>
                    )}
                    {/* <div
                      className="feature-box blue closeInfo"
                      onClick={toggleRandomChild}
                    >
                      Close
                    </div> */}
                  </div>
                )}

                {loadingStudents ? (
                  <>
                    <br />
                    <p>Loading...</p>
                  </>
                ) : (
                  <>
                    {!isAdmin && (
                      <>
                        {!isSponsor && (
                          <>
                            <div className="sensitive">
                              {" "}
                              <br />
                              <h3>Sensitive content</h3>
                              <p>
                                Please
                                <MyLink to="/login">
                                  <div className="feature-box blue">
                                    log in{" "}
                                  </div>
                                </MyLink>
                                to read our children's stories.
                              </p>
                              <p>
                                Our children come from very vulnerable
                                backgrounds. They have decided to share their
                                stories to the outside world, to give
                                themselves, and other children like themselves,
                                an opportunity at a better future.
                              </p>
                              <br />
                              <p>
                                At the moment, only residents of certain
                                countries are able to access their stories.
                                Kindly get in touch with us at{" "}
                                <u>
                                  <a href="mailto:magnus@thewatotolibrary.org">
                                    magnus@thewatotolibrary.org
                                  </a>
                                </u>{" "}
                                if you want to log in with a different phone
                                number than the ones available.
                              </p>
                              <p>
                                We ask that you respect our children's privacy
                                and refrain from sharing their stories publicly
                                in social media.
                              </p>
                              <br />
                            </div>
                          </>
                        )}
                      </>
                    )}

                    {isSponsor || isAdmin ? (
                      <>
                        {!loadingStudents ? (
                          <>
                            {!selectedStudent ? (
                              <div>
                                {studentsList.length > 0 ? (
                                  <div className="students-container">
                                    {studentsList.map((student, index) => (
                                      <div
                                        className="student-box"
                                        key={index}
                                        id={student.name}
                                      >
                                        <div className="image-container">
                                          <img
                                            src={student.image}
                                            alt={student.name}
                                            className="img student-image"
                                          />
                                        </div>
                                        <div className="text-container student-overview">
                                          <p className="student-name">
                                            <b>{student.name}</b> (
                                            {student.birthday &&
                                              calculateAge(student.birthday)}
                                            )
                                          </p>
                                          <p>Gender: {student.gender}</p>
                                          <p>
                                            Born:{" "}
                                            {student.birthday
                                              ? student.birthday
                                                  .toDate()
                                                  .toLocaleDateString()
                                              : "Unknown"}
                                          </p>
                                          <p>
                                            {sponsoredStudents.has(
                                              student.name
                                            ) ? (
                                              <div
                                                className="feature-box green goToStudent fullWidth"
                                                onClick={() =>
                                                  handleReadMore(student)
                                                }
                                              >
                                                <i className="fa-regular fa-circle-check"></i>{" "}
                                                {student.name.split(" ")[0]} has
                                                a sponsor
                                              </div>
                                            ) : (
                                              <div
                                                className="feature-box blue goToStudent fullWidth"
                                                onClick={() => {
                                                  handleReadMore(student);
                                                  setIsInfoVisible(false);
                                                }}
                                              >
                                                Read{" "}
                                                {student.name.split(" ")[0]}'s
                                                story{" "}
                                                <i className="fa-solid fa-arrow-right"></i>
                                              </div>
                                            )}
                                          </p>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                ) : (
                                  <div>
                                    <br />
                                    <p>No students yet.</p>
                                  </div>
                                )}
                              </div>
                            ) : (
                              <>
                                <div className="sponsor-navigation-container">
                                  <div className="sponsor-info-container">
                                    <div
                                      className="feature-box"
                                      style={{ whiteSpace: "nowrap" }}
                                      onClick={handleGoBack}
                                    >
                                      <i className="fa-solid fa-left-long"></i>{" "}
                                      <u className="goback">Go back</u>
                                    </div>

                                    {userData &&
                                      (userData.role === "Sponsor" ||
                                        userData.role === "Admin") && (
                                        <>
                                          {selectedStudent && sponsorStatus ? (
                                            <>
                                              <div className="feature-box green">
                                                <i className="fa-regular fa-circle-check"></i>{" "}
                                                {firstName} has a sponsor.
                                              </div>
                                              <span className="smallTxt">
                                                Page visible only because you
                                                are admin. <br />
                                                Users cannot see this page.
                                              </span>
                                            </>
                                          ) : (
                                            <a
                                              className="button start-sponsorship"
                                              href="#sponsor-child"
                                            >
                                              <i className="fa-solid fa-handshake-angle"></i>{" "}
                                              Sponsor {firstName}
                                            </a>
                                          )}
                                        </>
                                      )}
                                  </div>
                                  {filteredStudentsList.length > 1 && (
                                    <div
                                      className="change-child-container"
                                      id="/"
                                    >
                                      <div
                                        className="feature-box blue"
                                        onClick={handlePreviousStudent}
                                      >
                                        <i className="fa-solid fa-left-long"></i>{" "}
                                        Previous child
                                      </div>
                                      <div
                                        className="feature-box blue"
                                        onClick={handleNextStudent}
                                      >
                                        Next child{" "}
                                        <i className="fa-solid fa-right-long"></i>
                                      </div>
                                    </div>
                                  )}
                                </div>

                                <div
                                  className="student-box"
                                  style={{
                                    maxWidth: "100%",
                                    position: "relative",
                                  }}
                                >
                                  {isAdmin && (
                                    <button
                                      style={{
                                        position: "absolute",
                                        right: "10px",
                                        top: "10px",
                                      }}
                                      onClick={() => setEdit(!edit)}
                                    >
                                      {edit ? "Cancel" : "Edit"}
                                    </button>
                                  )}

                                  <div className="student-container-child">
                                    <div className="student-details-child">
                                      <p>
                                        {edit && (
                                          <>
                                            <button
                                              onClick={() =>
                                                handleStudentUpdate()
                                              }
                                            >
                                              SAVE CHANGES
                                            </button>
                                            <br />
                                            <br />
                                          </>
                                        )}
                                        <h3>
                                          {edit ? (
                                            <>
                                              Name:{" "}
                                              <input
                                                type="text"
                                                value={newStudentName}
                                                onChange={(e) =>
                                                  setNewStudentName(
                                                    e.target.value
                                                  )
                                                }
                                              />
                                            </>
                                          ) : (
                                            selectedStudent.name
                                          )}
                                        </h3>{" "}
                                      </p>
                                      <p>
                                        Gender:{" "}
                                        {edit ? (
                                          <select
                                            value={newStudentGender}
                                            onChange={(e) =>
                                              setNewStudentGender(
                                                e.target.value
                                              )
                                            }
                                          >
                                            <option value="" disabled>
                                              Select
                                            </option>
                                            <option value="Boy">Boy</option>
                                            <option value="Girl">Girl</option>
                                          </select>
                                        ) : (
                                          selectedStudent.gender
                                        )}
                                      </p>

                                      <p>
                                        Born:{" "}
                                        {edit ? (
                                          <input
                                            type="date"
                                            value={newStudentBirthday}
                                            onChange={(e) =>
                                              setNewStudentBirthday(
                                                e.target.value
                                              )
                                            }
                                          />
                                        ) : selectedStudent.birthday ? (
                                          <>
                                            {selectedStudent.birthday
                                              .toDate()
                                              .toLocaleDateString()}{" "}
                                            (
                                            {calculateAge(
                                              selectedStudent.birthday
                                            )}{" "}
                                            years)
                                          </>
                                        ) : (
                                          "Unknown"
                                        )}
                                      </p>

                                      <br />
                                      <div className="computer-only">
                                        {selectedStudent.description && (
                                          <>
                                            <h3>About {firstName}</h3>
                                            {edit ? (
                                              <textarea
                                                value={newStudentDescription}
                                                onChange={(e) =>
                                                  setNewStudentDescription(
                                                    e.target.value
                                                  )
                                                }
                                                style={{
                                                  width: "100%",
                                                  height: "400px",
                                                  border:
                                                    "0.05em solid rgb(237, 237, 237)",
                                                }}
                                              />
                                            ) : (
                                              <p>{firstParagraph}</p>
                                            )}
                                          </>
                                        )}
                                      </div>
                                    </div>

                                    <div className="image-container-child">
                                      {edit ? (
                                        <>
                                          <input
                                            type="file"
                                            onChange={handleImageUpload}
                                          />
                                          <img
                                            src={
                                              newStudentImage
                                                ? URL.createObjectURL(
                                                    newStudentImage
                                                  )
                                                : selectedStudent.image
                                            }
                                            alt="New student"
                                            className="img student-image-child"
                                          />
                                        </>
                                      ) : (
                                        <img
                                          src={selectedStudent.image}
                                          alt={selectedStudent.name}
                                          className="img student-image-child"
                                        />
                                      )}
                                    </div>
                                  </div>

                                  <br />
                                  <div className="mobile-only">
                                    <p>{firstParagraph}</p>
                                  </div>

                                  <div className="student-container-child">
                                    <div className="student-details-child">
                                      {!edit && (
                                        <p>
                                          {remainingParagraphs.map(
                                            (paragraph, index) => (
                                              <React.Fragment key={index}>
                                                <p>{paragraph}</p>
                                                <div
                                                  style={{ height: "5px" }}
                                                />
                                              </React.Fragment>
                                            )
                                          )}
                                        </p>
                                      )}
                                    </div>
                                  </div>

                                  <div className="student-container-child">
                                    <div className="student-details-child">
                                      <div className="computer-only">
                                        {selectedStudent.message && (
                                          <>
                                            <br />
                                            <h3>{firstName} writes</h3>
                                            {edit ? (
                                              <textarea
                                                value={newStudentMessage}
                                                onChange={(e) =>
                                                  setNewStudentMessage(
                                                    e.target.value
                                                  )
                                                }
                                                style={{
                                                  width: "100%",
                                                  height: "100px",
                                                  border:
                                                    "0.05em solid rgb(237, 237, 237)",
                                                }}
                                              />
                                            ) : (
                                              <i>{selectedStudent.message}</i>
                                            )}
                                          </>
                                        )}
                                      </div>
                                    </div>

                                    <br />

                                    <div className="image-container-child">
                                      {edit ? (
                                        <>
                                          <input
                                            type="file"
                                            onChange={handleImageUpload2}
                                          />
                                          <img
                                            src={
                                              newStudentImage2
                                                ? URL.createObjectURL(
                                                    newStudentImage2
                                                  )
                                                : selectedStudent.image2
                                            }
                                            alt="New student"
                                            className="img student-image-child"
                                          />
                                        </>
                                      ) : (
                                        <img
                                          src={selectedStudent.image2}
                                          alt={selectedStudent.name}
                                          className="img student-image-child"
                                        />
                                      )}
                                    </div>
                                  </div>

                                  <div className="mobile-only">
                                    {selectedStudent.message && (
                                      <>
                                        <br />
                                        <h3>{firstName} writes</h3>
                                        {edit ? (
                                          <textarea
                                            value={newStudentMessage}
                                            onChange={(e) =>
                                              setNewStudentMessage(
                                                e.target.value
                                              )
                                            }
                                            style={{
                                              width: "100%",
                                              height: "100px",
                                              border:
                                                "0.05em solid rgb(237, 237, 237)",
                                            }}
                                          />
                                        ) : (
                                          <i>{selectedStudent.message}</i>
                                        )}
                                      </>
                                    )}
                                  </div>

                                  <br />

                                  <div className="student-details-child">
                                    <p>
                                      {firstName} needs your help and support.
                                      You can sponsor {firstName} to have a
                                      better and brighter future for {pronoun_2}
                                      self and {pronoun} future generations to
                                      come. Your support will enable us to
                                      continue providing {pronoun_2} with access
                                      to quality education and assist with{" "}
                                      {pronoun} home situation, gifting{" "}
                                      {pronoun_2} with better living standards.
                                    </p>
                                    <br />
                                    {selectedStudent && sponsorStatus ? (
                                      ""
                                    ) : (
                                      <a
                                        className="button start-sponsorship"
                                        style={{ padding: "10px" }}
                                        href="#sponsor-child"
                                      >
                                        START A SPONSORSHIP NOW
                                      </a>
                                    )}

                                    <br />
                                    <br />
                                  </div>
                                </div>
                                <div className="change-child-container">
                                  <div
                                    className="feature-box blue"
                                    onClick={handlePreviousStudent}
                                  >
                                    <i className="fa-solid fa-left-long"></i>{" "}
                                    Previous child
                                  </div>
                                  <div
                                    className="feature-box blue"
                                    onClick={handleNextStudent}
                                  >
                                    Next child{" "}
                                    <i className="fa-solid fa-right-long"></i>
                                  </div>
                                </div>

                                <br />
                                {selectedStudent && sponsorStatus ? (
                                  ""
                                ) : (
                                  <div>
                                    <h3 id="sponsor-child">
                                      Select a payment option
                                    </h3>

                                    <div className="article sponsoroptions">
                                      <div
                                        className={`option ${
                                          activeOption === "norwegian"
                                            ? "active"
                                            : ""
                                        }`}
                                        onClick={() =>
                                          setActiveOption("norwegian")
                                        }
                                      >
                                        <p>🇳🇴 Norwegian</p>
                                      </div>

                                      <div
                                        className={`option ${
                                          activeOption === "other"
                                            ? "active"
                                            : ""
                                        }`}
                                        onClick={() => setActiveOption("other")}
                                      >
                                        {" "}
                                        <p>🌍 International</p>
                                      </div>
                                    </div>
                                    <br />

                                    {activeOption === "norwegian" ? (
                                      <>
                                        <SponsorForm firstName={firstName} />
                                      </>
                                    ) : (
                                      <>
                                        <h3>
                                          To sponsor a child costs $30 per
                                          month.
                                        </h3>
                                        <p>
                                          To sponsor {firstName} specifically,
                                          add his name under "Write us a
                                          comment". <br /> For random child,
                                          leave the field blank.
                                        </p>
                                        <br />
                                        <iframe
                                          src="https://donorbox.org/embed/donate-to-the-watoto-library?default_interval=m&amount=30"
                                          name="donorbox"
                                          allowpaymentrequest="allowpaymentrequest"
                                          seamless="seamless"
                                          frameborder="0"
                                          scrolling="no"
                                          height="900px"
                                          width="100%"
                                          style={{
                                            maxWidth: "500px",
                                            minWidth: "250px",
                                            maxHeight: "none!important",
                                            minHeight: "650px",
                                          }}
                                          allow="payment"
                                        ></iframe>
                                      </>
                                    )}
                                  </div>
                                )}
                              </>
                            )}
                          </>
                        ) : (
                          "Loading..."
                        )}
                      </>
                    ) : (
                      ""
                    )}
                  </>
                )}

                <br />
              </div>
            </div>

            <br />
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Sponsorship;
